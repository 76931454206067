import { Outlet, Navigate } from "react-router-dom";
const PrivateRoutes = () => {
  let role = localStorage.getItem("role");
  //   console.log(role);/
  const isMyTokenExpired = role == "admin";
  //   console.log(isMyTokenExpired);
  return isMyTokenExpired ? <Outlet /> : <Navigate to="/home" />;
};

export default PrivateRoutes;
