import React, { useEffect, useRef, useState, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { editText, editTranformText, getLibrary } from "../redux/actions/library";
import { ErrorBoundary } from "react-error-boundary";

const Embed = Quill.import("blots/embed");

class NonEditableBlot extends Embed {
  static create(value) {
    let node = super.create();
    node.setAttribute("contenteditable", false);
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }
}

NonEditableBlot.blotName = "noneditable";
NonEditableBlot.tagName = "span";
Quill.register(NonEditableBlot);

function QuillEditor({ text, selectedTransformation = null, selectedlibrary = null }) {
  const quillRef = useRef(null);
  const dispatch = useDispatch();
  const [speakerNames, setSpeakerNames] = useState({});

  const modules = useMemo(
    () => ({
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
      ],
    }),
    []
  );

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "size",
    "color",
    "background",
    "font",
    "align",
    "clean",
    "noneditable", // Add the custom blot to formats
  ];

  useEffect(() => {
    if (quillRef.current) {
      const quillInstance = quillRef.current.getEditor();

      quillInstance.root.setAttribute("spellcheck", false);

      quillInstance.on("text-change", (delta, oldDelta, source) => {
        if (source === "user") {
          const updatedText = quillInstance.root.innerHTML;
          saveToDatabase(updatedText);
        }
      });

      if (text) {
        try {
          quillInstance.setContents([]);
          const delta = quillInstance.clipboard.convert(text);
          quillInstance.setContents(delta, "silent");
        } catch (error) {
          // Handle known problematic tags
          let safeText = text
            // .replace(/<\/?b[^>]*>/g, "") // Remove <b> tags that may cause issues
            .replace(/<\/?ol[^>]*>/g, "") // Remove <ol> tags
            .replace(/<\/?ul[^>]*>/g, "") // Remove <ul> tags
            .replace(/<\/?li[^>]*>/g, "\n") // Replace <li> tags with newlines
            // .replace(/\n\s*\n/g, "\n") // Normalize multiple newlines to a single newline
            .trim()
            .split("\n")
            .map((line) => `<p>${line.trim()}</p>`) // Wrap each line in <p> tags
            .join("");

          quillInstance.clipboard.dangerouslyPasteHTML(safeText);

          console.error("Error setting Quill content:", error);
        }
      }

      const handleClick = (event) => {
        const timestampPattern = /<span style="color: rgb\(136, 136, 136\);">[\d:\s-]+<\/span>/;
        const outerHTML = event.target.outerHTML;
        const timestampMatch = outerHTML.match(timestampPattern);

        if (timestampMatch) {
          const target = event.target;
          const isSpeaker = target.closest("span[contenteditable='false']");

          if (isSpeaker) {
            const speakerText = target.closest("span[contenteditable='false']").outerHTML;
            openModal(speakerText);
          }
        }
      };

      quillInstance.root.addEventListener("click", handleClick);

      return () => {
        quillInstance.root.removeEventListener("click", handleClick);
      };
    }
  }, [text, selectedTransformation, selectedlibrary]);

  const saveToDatabase = async (updatedText) => {
    if (selectedTransformation && selectedTransformation.id) {
      await dispatch(await editTranformText(selectedTransformation.id, updatedText));
    } else if (selectedlibrary && selectedlibrary.id) {
      await dispatch(await editText(selectedlibrary.id, updatedText));
    }
  };

  const openModal = (outerHTML) => {
    const timestampPattern = /<span style="color: rgb\(136, 136, 136\);">[\d:\s-]+<\/span>/;
    const timestampMatch = outerHTML.match(timestampPattern);

    if (timestampMatch) {
      const speakerLabelHTML = outerHTML.split(timestampMatch[0])[0];
      const speakerLabel = speakerLabelHTML.replace(/<[^>]*>|:/g, "").trim();
      if (["", " "].includes(speakerLabel)) return;
      const newName = prompt(`Enter new name for ${speakerLabel}`, "");
      if (newName && newName !== speakerLabel) {
        setSpeakerNames((prevNames) => ({ ...prevNames, [speakerLabel]: newName }));
        updateSpeakerName(speakerLabel, newName);
      }
    }
  };

  const updateSpeakerName = (oldName, newName) => {
    const quillInstance = quillRef.current.getEditor();
    let editorContent = quillInstance.root.innerHTML;

    const regex = new RegExp(oldName, "g");
    const updatedContent = editorContent.replace(regex, newName);

    quillInstance.root.innerHTML = updatedContent;
  };

  useEffect(() => {
    for (const [oldName, newName] of Object.entries(speakerNames)) {
      updateSpeakerName(oldName, newName);
    }
  }, [speakerNames]);

  return (
    <ErrorBoundary fallback={text ? <div dangerouslySetInnerHTML={{ __html: text }} /> : <div>Something's Wrong. Retry please.</div>}>
      <>
        <style>
          {`
            .ql-container.ql-snow {
              border: 0px solid #e5e7eb;
            }
            .ql-toolbar.ql-snow {
              border: 1px solid #e5e7eb;
              border-left: 0;
              border-right: 0;
              border-top: 0;
            }
          `}
        </style>
        <div id="editor-container" style={{ width: "100%", height: "70vh" }}>
          <ReactQuill
            ref={quillRef}
            style={{
              height: "100%",
              paddingBottom: "4.00rem",
              overflowX: "scroll",
              overflowY: "hidden",
            }}
            theme="snow"
            modules={modules}
            formats={formats}
            readOnly={false}
            placeholder=""
          />
        </div>
      </>
    </ErrorBoundary>
  );
}

export default QuillEditor;
