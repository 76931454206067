const initState = {
  loading: false,
  user: null,
  selectedUseCaseId: null,
  selectedUseCase: {},
  useCases: [],
  connectedPlatforms: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        loading: !state.loading,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_CONNECTED_PLATFORMS":
      return {
        ...state,
        connectedPlatforms: action.payload,
      };
    case "SET_USECASES":
      return {
        ...state,
        useCases: action.payload,
      };
    case "SET_SELECTED_USECASES":
      return {
        ...state,
        selectedUseCaseId: action.payload.id,
        selectedUseCase: action.payload,
      };
    case "LOGOUT":
      return {
        ...initState,
        user: null,
      };
    default:
      return state;
  }
};
