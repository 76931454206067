import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";

export const getLibrary =
  (isFirstRun = true) =>
  async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return false;
      }
      dispatch({ type: "GET_LIBRARY", payload: isFirstRun });
      const { data, status } = await authenticatedInstance.get("/library/get-all");
      if (status === 200) {
        dispatch({
          type: "SET_LIBRARIES",
          payload: data.library,
        });
        return true;
      }
    } catch (error) {
      console.log(error, "Error in signup");
      // toast.error("Something went wrong!")
      return false;
    }
  };
export const getOneLibrary =
  (id, load = false) =>
  async (dispatch) => {
    if (!id) return;
    try {
      dispatch({ type: "GET_LIBRARY", payload: load });
      const { data, status } = await authenticatedInstance.get(`/library/get/${id}`);
      if (status === 200) {
        dispatch({
          type: "SET_SELECTED_LIBRARY",
          payload: data.library,
        });
        return true;
      }
    } catch (error) {
      console.log(error, "Error!");
      // toast.error("Something went wrong!")
      return false;
    }
  };
export const getSharedLibrary = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_SHARED_LIBRARY" });
    const { data, status } = await instance.get(`/library/get-shared/${id}`);
    if (status === 200) {
      dispatch({
        type: "SET_SHARED_SELECTED_LIBRARY",
        payload: data.library,
      });
      return true;
    }
  } catch (error) {
    console.log(error, "Error!");
    // toast.error("Something went wrong!")
    return false;
  }
};
export const getShareLink = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_LIBRARY" });
    const { data, status } = await authenticatedInstance.get(`/library/get-share-link?id=${id}`);
    if (status === 200) {
      dispatch({
        type: "SET_SELECTED_LIBRARY",
        payload: data.library,
      });
      return data.library;
    }
  } catch (error) {
    console.log(error, "Error!");
    // toast.error("Something went wrong!")
    return false;
  }
};
export const reOrderTransformation = (payload, library) => async (dispatch) => {
  try {
    await dispatch({
      type: "SET_SELECTED_LIBRARY",
      payload: library,
    });
    await authenticatedInstance.post("/library/order-transformation", {
      payload,
    });
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};
export const deleteTransformationOrder = (payload, id) => async (dispatch) => {
  try {
    await authenticatedInstance.post("/library/delete-transformation", {
      payload,
      id,
    });
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};

export const createLib =
  (text, time = null, assetId = null) =>
  async (dispatch) => {
    try {
      const { data, status } = await authenticatedInstance.post("/library/create", {
        text,
        time,
        assetId,
      });
      if (status === 200) {
        dispatch({
          type: "SET_SELECTED_LIBRARY",
          payload: data.library,
        });
        return data.library;
      }
    } catch (error) {
      console.log(error, "Error in signup");
      // toast.error("Something went wrong!")
      return false;
    }
  };

export const saveTrans = (transId) => async (dispatch) => {
  try {
    const { status } = await authenticatedInstance.post("/transformation/save", {
      transId,
    });
    if (status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};

export const setSelectedLibrary = (library) => async (dispatch) => {
  console.log(library);
  try {
    await dispatch({
      type: "SET_SELECTED_LIBRARY",
      payload: library,
    });
    return true;
  } catch (error) {
    console.log(error, "Error in setting selected library");
    return false;
  }
};

export const createTransform = (useCaseId, libraryId, fieldsVal) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post("/transformation/create", {
      useCaseId,
      libraryId,
      fieldsVal,
    });
    if (status === 200) {
      return {
        status: true,
        id: data.id,
      };
    }
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};

export const changeName =
  (name, id, description = "") =>
  async (dispatch) => {
    try {
      if (!id || !name) {
        return false;
      }
      const { data } = await authenticatedInstance.put("/library/edit-name", {
        name,
        id,
        description,
      });
      dispatch({
        type: "SET_NAME",
        payload: data.library,
      });
      return true;
    } catch (error) {
      console.log(error, "Error in  selected name");
      return false;
    }
  };
export const changeTransName = (id, title) => async (dispatch) => {
  try {
    await authenticatedInstance.put("/library/edit-trans-title", {
      title,
      id,
    });
    return true;
  } catch (error) {
    console.log(error, "Error in  selected name");
    return false;
  }
};

export const deleteLibraryById = (id) => async (dispatch) => {
  try {
    await authenticatedInstance.delete(`/library/delete/${id}`, {
      id,
    });
    dispatch({
      type: "DELETE_LIBRARY",
      payload: id,
    });
    return true;
  } catch (error) {
    console.log(error, "Error in  delete");
    return false;
  }
};
export const deleteTransById = (id) => async (dispatch) => {
  try {
    await authenticatedInstance.post(`/transformation/delete`, {
      id,
    });
    dispatch({
      type: "DELETE_TRANS",
      payload: id,
    });
    return true;
  } catch (error) {
    console.log(error, "Error in  delete");
    return false;
  }
};

export const updateEditLibrary = (text) => async (dispatch) => {
  try {
    const { data } = await authenticatedInstance.update("", {
      text,
    });
    dispatch({
      type: "SET_EDIT_LIBRARY",
      payload: data,
    });
  } catch (error) {
    console.log(error, "Error in updating library");
    return false;
  }
};

export const copyLibrary = (id) => async (dispatch) => {
  try {
    const response = await authenticatedInstance.post(`/library/copy/${id}`, {
      id,
      isCopy: true,
    });
    console.log("Copy Library Response:", response);
    dispatch({
      type: "COPY_LIBRARY",
      payload: response.data.id,
    });
  } catch (error) {
    console.error("Error copying library:", error);
  }
};

export const editText = (id, text) => async (dispatch) => {
  try {
    if (!id || !text) {
      return false;
    }

    await authenticatedInstance.post("/library/edit-text", {
      id,
      text,
    });

    // dispatch({
    //   type: "SET_SELECTED_LIBRARY",
    //   payload: data.library,
    // });
    return true;
  } catch (error) {
    console.log(error, "Error in editing text");
    return false;
  }
};
export const editTranformText = (id, text) => async (dispatch) => {
  try {
    if (!id || !text) {
      return false;
    }

    await authenticatedInstance.post("/transformation/edit-text", {
      id,
      text,
    });

    // dispatch({
    //   type: "SET_SELECTED_LIBRARY",
    //   payload: data.library,
    // });
    return true;
  } catch (error) {
    console.log(error, "Error in editing text");
    return false;
  }
};
