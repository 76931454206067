import { Outlet, Navigate } from "react-router-dom";
const PaidRoutes = () => {
  let planStatus = localStorage.getItem("planStatus");
  let subExpiresAt = localStorage.getItem("subExpiresAt");
  //   console.log(role);/
  const isMyTokenExpired = planStatus == "active" && new Date() < new Date(subExpiresAt);
  //   console.log(isMyTokenExpired);
  return isMyTokenExpired ? <Outlet /> : <Navigate to="/billing" />;
};

export default PaidRoutes;
