import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../redux/actions/AxiosInstance/authenticated";

const UseCaseTable = () => {
  const [sortedUseCases, setSortedUseCases] = useState([]);
  const navigate = useNavigate();

  const useCases = useSelector((state) => state.auth.useCases);

  useEffect(() => {
    const sorted = [...useCases].sort((a, b) => a.id - b.id);
    setSortedUseCases(sorted);
  }, [useCases]);

  const handleCheckboxChange = async (useCaseId, newValue) => {
    try {
      const updatedUseCases = sortedUseCases.map((useCase) => {
        if (useCase.id === useCaseId) {
          return { ...useCase, active: newValue };
        }
        return useCase;
      });
      setSortedUseCases(updatedUseCases);
      // Send a request to update the 'active' field in the database
      await axios.put(`/use-cases/update/${useCaseId}`, { active: newValue });
      // Create a new array with updated values
      // Update the state with the new array
    } catch (error) {
      console.error("Error updating use case:", error);
    }
  };

  return (
    <div className="container p-4 mx-auto">
      <div className="flex justify-between w-full">
        <h1 className="my-2 text-2xl font-bold">Use Cases</h1>
        <div className="py-2">
          <button onClick={() => navigate("/dvp/admin/usecase/add")} className="btn-primary">
            Add Use Case
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-4 py-2 border">ID</th>
            <th className="px-4 py-2 border">Icon</th>
            <th className="px-4 py-2 border">Name</th>
            <th className="px-4 py-2 border">Description</th>
            <th className="px-4 py-2 border">Active</th>
            <th className="px-4 py-2 border">Actions</th>
          </tr>
        </thead>

        <tbody>
          {sortedUseCases.map((useCase) => (
            <tr key={useCase.id}>
              <td className="px-4 py-2 border">{useCase.id}</td>
              <td className="px-4 py-2 border">{useCase.icon}</td>
              <td className="px-4 py-2 border">{useCase.name}</td>
              <td className="px-4 py-2 border">{useCase.description}</td>
              <td className="px-4 py-2 border">
                <input type="checkbox" checked={useCase.active} onChange={(e) => handleCheckboxChange(useCase.id, e.target.checked)} />
              </td>
              <td className="px-4 py-2 border">
                <button onClick={() => navigate(`/dvp/admin/usecase/edit/${useCase.id}`)} className="px-4 py-2 btn-primary ">
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UseCaseTable;
