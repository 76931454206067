import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { Dropdown, message, Select } from "antd";
import { BiCopy } from "react-icons/bi";
import { BiExport } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import QuillEditor from "../../components/QuillEditor";
import { changeName, editText, getOneLibrary } from "../../redux/actions/library";
import MainLoader from "../../components/MainLoader";
import { MdArrowBackIos } from "react-icons/md";
import logo from "../../assets/logo.png";
import { BsLightningCharge } from "react-icons/bs";
const Transcript = ({}) => {
  const { selectedlibrary, loading } = useSelector((state) => state.library);
  const { user } = useSelector((state) => state.auth);
  const { libId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const transcriptText = location.state?.text;
  const [nameChange, setNameChange] = useState(selectedlibrary?.name);
  const [text, setText] = useState(selectedlibrary?.text);

  useEffect(() => {
    if (selectedlibrary && !nameChange) {
      setNameChange(selectedlibrary?.name);
    }
    if (selectedlibrary && !text) {
      setText(selectedlibrary?.text);
    }
    if (!selectedlibrary && libId) {
      dispatch(getOneLibrary(libId, true));
    }
  }, [selectedlibrary]);

  const handleEdit = async (e, id) => {
    setNameChange(e.target.value);
    // console.log(e.target.value);
    // console.log(id);
    dispatch(changeName(e.target.value, id, selectedlibrary?.description));
  };

  const handleSubmit = async () => {
    try {
      // dispatch(editText(selectedlibrary.id, text));
      message.success("Changes saved successfully!");
      // navigate("/notes");
    } catch (error) {
      message.error("Error occurred while saving changes.");
    }
  };
  const handleExport = () => {
    if (selectedlibrary) {
      const textToExport = selectedlibrary?.text || "No content to export";
      downloadTxtFile(textToExport, "notes_output.txt");
    }
  };

  const downloadTxtFile = (textToExport, fileName) => {
    // Replace all HTML tags with line breaks and keep the line breaks in the text
    const plainText = textToExport
      .replace(/<\/?[^>]+(>|$)/g, "") // Remove all HTML tags
      .replace(/<\/p>/g, "\n") // Add a line break where </p> tags were
      .replace(/<\/li>/g, "\n") // Add a line break where </li> tags were
      .replace(/<br\s*\/?>/gi, "\n") // Handle <br> tags as line breaks
      .replace(/\n\s*\n/g, "\n") // Remove any extra newlines
      .trim(); // Trim any leading/trailing spaces or newlines

    const element = document.createElement("a");
    const file = new Blob([plainText], { type: "text/plain;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  const handleCopy = () => {
    if (selectedlibrary) {
      const textToCopy = selectedlibrary?.text || "No content to copy";

      // Replace all HTML tags with line breaks and keep the line breaks in the text
      const plainText = textToCopy
        .replace(/<\/?[^>]+(>|$)/g, "") // Remove all HTML tags
        .replace(/<\/p>/g, "\n") // Add a line break where </p> tags were
        .replace(/<\/li>/g, "\n") // Add a line break where </li> tags were
        .replace(/<br\s*\/?>/gi, "\n") // Handle <br> tags as line breaks
        .replace(/\n\s*\n/g, "\n") // Remove any extra newlines
        .trim(); // Trim any leading/trailing spaces or newlines

      const textarea = document.createElement("textarea");
      textarea.value = plainText;
      document.body.appendChild(textarea);

      // Select the text inside the textarea
      textarea.select();
      textarea.setSelectionRange(0, 99999); // For mobile devices

      // Execute the copy command
      document.execCommand("copy");
      document.body.removeChild(textarea);

      alert("Content copied to clipboard!");
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="flex flex-row items-center justify-start gap-2 w-[90px]  " onClick={handleCopy}>
          <BiCopy />
          <p className="font-semibold">Copy</p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex flex-row items-center justify-start gap-2 w-[90px]  " onClick={handleExport}>
          <BiExport />
          <p className="font-semibold">Export</p>
        </div>
      ),
    },
  ];

  const handleBack = () => {
    navigate("/notes");
  };

  return (
    <>
      <img src={"/images/logo.png"} alt="logo" className="w-20 mx-auto my-10 text-center hidden md:block" />

      {loading ? (
        <MainLoader />
      ) : (
        <>
          <div className="flex items-center justify-between px-2 md:justify-between w-full md:w-2/3 mx-auto mt-6">
            <div className="flex items-center justify-center gap-2 ml-0 md:-ml-24">
              <MdArrowBackIos className="font-semibold text-[24px] cursor-pointer" onClick={handleBack} />
              <img src={logo} className="md:hidden" />
            </div>
            {user && !user?.infiniteCeiling ? (
              <div className="flex gap-1 cursor-pointer w-[68px] h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
                <BsLightningCharge className="font-semibold" />
                <span>{Math.max(0, user.monthlyCreditCeiling - user.currentMonthUsage)}</span>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-4 px-2 mt-6 md:px-0">
            <div className={`bg-white h-auto rounded-xl border border-[#EAEAEA]  w-full md:w-2/3 flex flex-col  items-center ${!false && " justify-between gap-0"}`}>
              <div className="flex items-center justify-start w-full">
                <svg width="41" height="41" className="m-3" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="41" height="41" rx="5" fill="#F2F5F7" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.9024 13.2723C17.7243 12.4577 18.8389 12 20.0012 12C21.1634 12 22.2781 12.4577 23.0999 13.2723C23.9218 14.087 24.3835 15.1918 24.3835 16.3439V21.0038C24.3835 22.1558 23.9218 23.2607 23.0999 24.0754C22.2781 24.89 21.1634 25.3477 20.0012 25.3477C18.8389 25.3477 17.7243 24.89 16.9024 24.0754C16.0806 23.2607 15.6189 22.1558 15.6189 21.0038V16.3439C15.6189 15.1918 16.0806 14.087 16.9024 13.2723ZM20.0012 13.6981C19.2933 13.6981 18.6143 13.9769 18.1138 14.4731C17.6132 14.9692 17.332 15.6422 17.332 16.3439V21.0038C17.332 21.7055 17.6132 22.3784 18.1138 22.8746C18.6143 23.3708 19.2933 23.6496 20.0012 23.6496C20.7091 23.6496 21.388 23.3708 21.8886 22.8746C22.3892 22.3784 22.6704 21.7055 22.6704 21.0038V16.3439C22.6704 15.6422 22.3892 14.9692 21.8886 14.4731C21.388 13.9769 20.7091 13.6981 20.0012 13.6981ZM11.7333 21.3285C12.2014 21.261 12.6362 21.5824 12.7043 22.0464C12.9594 23.784 13.8371 25.3724 15.1768 26.521C16.5165 27.6697 18.2286 28.3019 20 28.3019C21.7714 28.3019 23.4835 27.6697 24.8232 26.521C26.1629 25.3724 27.0406 23.784 27.2957 22.0464C27.3638 21.5824 27.7986 21.261 28.2667 21.3285C28.7348 21.396 29.0591 21.827 28.991 22.291C28.6766 24.4323 27.5949 26.3898 25.9439 27.8053C24.2929 29.2209 22.183 30 20 30C17.817 30 15.7071 29.2209 14.0561 27.8053C12.4051 26.3898 11.3234 24.4323 11.009 22.291C10.9409 21.827 11.2652 21.396 11.7333 21.3285Z"
                    fill="#A9BED0"
                  />
                </svg>
                <div className="w-full">
                  <input className="w-full text-base font-bold focus:outline-none" contentEditable="true" value={nameChange} onChange={(e) => handleEdit(e, selectedlibrary.id)}></input>
                  <p className="text-[#C4C4C4] text-xs">{selectedlibrary && selectedlibrary.createdAt ? new Date(selectedlibrary.createdAt).toDateString() : "No date available"}</p>
                </div>
              </div>
            </div>
            {selectedlibrary && selectedlibrary?.presignedUrl && (
              <div className={`bg-white h-auto rounded-xl border border-[#EAEAEA]  w-full md:w-2/3 flex flex-col  items-center ${!false && " justify-between gap-0"}`}>
                <div className="flex items-center justify-start w-full">
                  <div className="w-full p-3">
                    {/* play audio */}
                    <audio controls preload="auto" className="w-full">
                      <source src={selectedlibrary?.presignedUrl} type="audio/mp3" />
                      <source src={selectedlibrary?.presignedUrl} type="audio/mp4" />
                      <source src={selectedlibrary?.presignedUrl} type="audio/ogg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>
              </div>
            )}
            <div className={`bg-white h-auto rounded-xl border border-[#EAEAEA]  w-full md:w-2/3 flex flex-col  items-center ${!false && " justify-between gap-0"}`}>
              <div className="flex justify-between w-full border-b">
                <div></div>
                <Dropdown
                  menu={{
                    items,
                  }}
                  className=""
                  placement="bottom">
                  <svg width="4" height="14" className="z-40 mx-3 my-3 cursor-pointer" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1.75" cy="1.75" r="1.75" fill="#B0AEAE" />
                    <circle cx="1.75" cy="7" r="1.75" fill="#B0AEAE" />
                    <circle cx="1.75" cy="12.25" r="1.75" fill="#B0AEAE" />
                  </svg>
                </Dropdown>
              </div>
              <QuillEditor text={selectedlibrary?.text} selectedlibrary={selectedlibrary} />
            </div>

            <div className="w-full mb-16 md:w-2/3">
              <div className="flex items-center justify-end w-full gap-4 py-8">
                <button
                  onClick={() => handleSubmit(text)}
                  className="border-2 text-primary font-bold border-primary rounded-xl text-xs md:text-sm px-5 md:px-7 py-[0.50rem] hover:shadow-primary hover:shadow-xs hover:bg-primary hover:text-white">
                  Save
                </button>

                <button
                  onClick={() => navigate("/templates")}
                  className="btn-primary rounded-xl font-bold px-4 md:px-7 text-xs md:text-sm py-[0.65rem] hover:bg-white hover:border-2 hover:text-primary">
                  Create Content
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Transcript;
