import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";
import axios from "axios";

export const handleSignIn = async (options) => {
  try {
    const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/user/signin`, options, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Logged in successfully!");
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signin");
    toast.error("Something went wrong!");
    return false;
  }
};
export const autoLoginUser = async (accessToken, refreshToken) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
  toast.success("Logged in successfully!");
  return true;
};

export const handleSignUp = async (options) => {
  try {
    const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, options, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Signed up successfully!");
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    toast.error("Something went wrong!");
    return false;
  }
};

export const getUserInfo = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return false;
    }
    const { data, status } = await authenticatedInstance.get("/user/get-user-info");
    if (status === 200) {
      dispatch({
        type: "SET_USER",
        payload: data.user,
      });
      localStorage.setItem("role", data.user.role);
      localStorage.setItem("planStatus", data.user.planStatus);
      localStorage.setItem("subExpiresAt", data.user.subExpiresAt);
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};
export const markOnBoardingDone = () => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get("/user/mark-onboarded");
    if (status === 200) {
      dispatch({
        type: "SET_USER",
        payload: data.user,
      });

      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};
export const getUseCases = () => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get("/use-cases/get-all");
    if (status === 200) {
      dispatch({
        type: "SET_USECASES",
        payload: data.useCases,
      });
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};

export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("planStatus");
    localStorage.removeItem("role");
    localStorage.removeItem("loglevel");

    dispatch({
      type: "LOGOUT",
      payload: "logout",
    });
    return true;
  } catch (error) {
    console.log(error, "Error in signup");

    return false;
  }
};
