import React, { useEffect, useState } from "react";
import axios from "../redux/actions/AxiosInstance/authenticated";
import { useSelector } from "react-redux";
import { BsStopwatch } from "react-icons/bs";
import { useLocation } from "react-router-dom"; // Import useLocation
import { getUserInfo } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { message, Modal } from "antd";
import MainLoader from "../components/MainLoader";
import { logOut } from "../redux/actions/auth";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const Plans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const location = useLocation(); // Hook to get location object
  const { user } = useSelector((state) => state.auth);
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");
  const planTab = queryParams.get("plan");
  const [plan, setPlan] = useState(null);
  const [planType, setPlanType] = useState("");
  useEffect(() => {
    if (planTab) {
      setPlan(planTab);
    } else {
      if (user) setPlan(String(user.planType).split("-")[1]);
    }
  }, [planTab, user]);
  const handlesubscription = async (pt) => {
    if (user.planType == pt) {
      message.info("You are already on this plan");
      return;
    }
    const btns = document.getElementsByTagName("button");
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = true;
      btns[i].style.opacity = 0.5;
    }
    message.loading("Processing...");
    try {
      const response = await axios.post(`/stripe/create-checkout-session`, {
        plantype: pt,
      });
      dispatch(getUserInfo());
      if (response.data.status) {
        if (response.data.url) window.location = response.data.url;
        message.success(response.data.message ? response.data.message : "Subscription updated successfully");
      } else {
        message.error(response.data.message ? response.data.message : "An error occured");
      }
    } catch (error) {
      console.log(error.message);
      message.error("An error occured");
    }
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = false;
      btns[i].style.opacity = 1;
    }
  };
  const showModal = (pt) => {
    setPlanType(pt);
    if (user.planStatus == "inactive") {
      handlesubscription(pt);
      return;
    }

    if (user.planType == pt) {
      message.info("You are already on this.");
      return;
    }
    if (String(user.planType).includes("lifetime") && !String(pt).includes("lifetime")) {
      message.info("You are on a lifetime plan.");
      return;
    }
    if (String(user.planType).includes("pro-lifetime")) {
      message.info("You are on a pro lifetime plan.");
      return;
    }
    setOpen(true);

    // handlesubscription(pt);
    // handlesubscription(plantype);
    // set time out and then set
  };
  const cancelSub = async () => {
    const btns = document.getElementsByTagName("button");
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = true;
      btns[i].style.opacity = 0.5;
    }
    message.loading("Processing...");
    try {
      const response = await axios.post(`/stripe/cancel-subscription`);
      dispatch(getUserInfo());
      if (response.data.status) {
        message.success(response.data.message ? response.data.message : "Subscription updated successfully");
      } else {
        message.error(response.data.message ? response.data.message : "An error occured");
      }
    } catch (error) {
      console.log(error.message);
      message.error("An error occured");
    }
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = false;
      btns[i].style.opacity = 1;
    }
  };
  if (!user) {
    return <MainLoader />;
  }
  const handleLogout = async () => {
    await dispatch(logOut());
    navigate("/signin");
    window.location.reload();
  };
  return (
    user && (
      <div className="flex flex-col mt-20 md:overflow-hidden">
        <img src={"/images/logo.png"} alt="logo" className="text-center w-20 mx-auto -mt-12 mb-0 md:mt-0 md:mb-0" />
        <div className="absolute top-10 right-5 flex flex-row items-center justify-center gap-1 " onClick={handleLogout}>
          <span className={`cursor-pointer `}>Logout</span>
          <FiLogOut className="cursor-pointer " />
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center md:mt-2 ml-0">
          <div className="bg-white p-8 mb-8 w-full md:w-[90%] lg:w-[85%] xl:w-[75%]">
            <Modal
              title=""
              open={open}
              onOk={() => {
                handlesubscription(planType);
                setOpen(false);
              }}
              onCancel={() => {
                setPlanType("");
                setOpen(false);
              }}
              okText="Yes"
              okButtonProps={{ className: "btn-primary hover:btn-secondary border-0 hover:opacity-70" }}
              cancelText="Cancel">
              <p>Are you sure you want to update your plan to {planType}</p>
            </Modal>
            <Modal
              title=""
              open={openCancel}
              onOk={() => {
                cancelSub();
                setOpenCancel(false);
              }}
              onCancel={() => {
                setOpenCancel(false);
              }}
              okText="Yes"
              okButtonProps={{ className: "btn-primary bg-red-600 hover:btn-secondary border-0 hover:opacity-70" }}
              cancelText="Cancel">
              <p>Are you sure you want to cancel your plan?</p>
            </Modal>
            <div className="w-full">
              <div className="mx-auto text-center mb-12 text-xl">
                <h1 className="text-2xl">Lifetime pass</h1>

                <p className="text-xs -mt-1 italic text-red-500">Limited time offer</p>
                <p className="text-lg text-center">
                  <BsStopwatch className="text-center mx-auto" />
                </p>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-6 items-center ml-0 ">
                {/* Starter Plan */}

                <div className="border rounded-lg shadow p-6 pb-3 w-full md:w-1/3">
                  <h3 className="text-lg font-bold text-purple-700 mb-4">Starter plan</h3>
                  <ul
                    className="mb-6"
                    style={{
                      listStyleType: "none",
                    }}>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">420 mins of audio per month</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Upload audio, YouTube, Google Meet, mic recording</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Unlimited audio length</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Unlimited Audio/Video files</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Edit output & transcript</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Magic generator</div>
                    </li>
                  </ul>
                  <div className="text-center">
                    <button className="btn-primary mb-6 w-36" type="button" onClick={() => showModal("starter-lifetime")}>
                      $299 - Forever
                    </button>
                  </div>
                </div>

                {/* Pro Plan */}
                <div className="border rounded-lg shadow p-6 pb-3 w-full md:w-1/3">
                  <h3 className="text-lg font-bold text-purple-700 mb-4">Pro plan</h3>
                  <ul
                    className="mb-6"
                    style={{
                      listStyleType: "none",
                    }}>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">900 mins of audio per month</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Upload audio, YouTube, Google Meet, mic recording</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Unlimited audio length</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Unlimited Audio/Video files</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Edit output & transcript</div>
                    </li>
                    <li className="flex">
                      <div>✔️</div>
                      <div className="ml-1">Magic generator</div>
                    </li>
                  </ul>
                  <div className="text-center">
                    <button className="btn-primary mb-6 w-36" type="button" onClick={() => showModal("pro-lifetime")}>
                      $579 - Forever
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-row mt-4">
                <div className="w-1/2 md:w-[21%] py-4 text-center">
                  {/* <div className="font-semibold">Monthly</div>
                  <div className="my-8 font-semibold">Yearly pass</div> */}
                </div>
                <div className="w-1/2 md:w-1/3 text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Plans;
