const initState = {
  loading: true,
  selectedlibraryId: null,
  selectedlibrary: null,
  sharedLibrary: null,
  sharedLibraryId: null,
  libraries: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        loading: !state.loading,
      };
    case "SET_LIBRARIES":
      return {
        ...state,
        loading: false,
        libraries: action.payload,
        selectedlibrary: state.selectedlibrary ? action.payload.find((lib) => lib.id === state.selectedlibrary.id) : null,
      };
    case "GET_LIBRARY":
      return {
        ...state,
        loading: action.payload,
      };
    case "GET_SHARED_LIBRARY":
      return {
        ...state,
        loading: true,
      };
    case "SET_NAME":
      return {
        ...state,
        // selectedlibrary: action.payload,
        //  update libraries array and replace one ele with selectedlibrary
        libraries: state.libraries.map((lib) => (lib.id === action.payload.id ? action.payload : lib)),
      };
    case "SET_TEXT":
      return {
        ...state,
        libraries: state.libraries.map((lib) => (lib.id === action.payload.id ? { ...lib, text: action.payload.text } : lib)),
      };
    case "SET_SELECTED_LIBRARY":
      return {
        ...state,
        loading: false,
        selectedlibraryId: action.payload.id,
        selectedlibrary: action.payload,
      };
    case "SET_SHARED_SELECTED_LIBRARY":
      return {
        ...state,
        loading: false,
        sharedLibraryId: action.payload.id,
        sharedLibrary: action.payload,
        loading: false,
      };
    case "DELETE_LIBRARY":
      const updatedLibraries = state.libraries.filter((library) => library.id !== action.payload);
      return {
        ...state,
        loading: false,
        libraries: updatedLibraries,
      };
    case "DELETE_TRANS":
      console.log(state.selectedlibrary);
      const transformations = state?.selectedlibrary?.transformations?.filter((library) => library.id !== action.payload);
      return {
        ...state,
        loading: false,
        selectedlibrary: {
          ...state.selectedlibrary,
          transformations,
        },
      };
    case "COPY_LIBRARY":
      const copiedLibraryId = action.payload;
      const sourceLibrary = state.libraries.find((library) => library.id === copiedLibraryId);
      const copiedLibrary = {
        ...sourceLibrary,
        name: `Copied - ${sourceLibrary.name}`,
      };

      return {
        ...state,
        loading: false,
        libraries: [...state.libraries, copiedLibrary],
      };

    case "SET_EDIT_LIBRARY":
      return {
        ...state,
        loading: false,
        text: action.payload,
      };
    default:
      return state;
  }
};
