import React, { useEffect, useState, useRef } from "react";
import QuillEditor from "../../components/QuillEditor";
import { MdArrowBackIos, MdMoreVert } from "react-icons/md";
import { BsLightningCharge } from "react-icons/bs";
import { BiExport, BiPencil, BiCopy } from "react-icons/bi";
import { RiDeleteBinLine, RiEyeLine } from "react-icons/ri";
import { IoArrowUpOutline, IoArrowDownOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../../components/MainLoader";
import { useNavigate, useParams } from "react-router-dom";
import { changeName, changeTransName, deleteTransById, getOneLibrary, getShareLink, reOrderTransformation, deleteTransformationOrder } from "../../redux/actions/library";
import { Dropdown, Modal } from "antd";
const Transformation = () => {
  const { libId } = useParams();
  const { selectedlibrary, loading } = useSelector((state) => state.library);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // ;
  const isFirstRun = useRef(true); // useRef to keep track of the first run

  useEffect(() => {
    // Function to refresh the library
    const refreshLibrary = () => {
      if (isFirstRun.current) {
        console.log("Running for the first time");
        isFirstRun.current = false; // Set the ref to false after the first run
      } else {
        console.log("Running on interval");
      }
      if (!selectedlibrary) {
        dispatch(getOneLibrary(libId, isFirstRun.current));
      } else {
        dispatch(getOneLibrary(selectedlibrary.id));
      }
    };
    // Call the function immediately to load the library the first time
    refreshLibrary();

    // Set up the interval to refresh the library every 10 seconds
    const intervalId = setInterval(refreshLibrary, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, libId]);

  // console.log(selectedlibrary);
  const navigate = useNavigate();
  const [selectedTransformation, setSelectedTransformation] = useState(selectedlibrary?.transformations.length > 0 && selectedlibrary?.transformations[0]);
  const [nameChange, setNameChange] = useState(selectedlibrary?.name);
  const [description, setDescription] = useState(selectedlibrary?.description);
  const [transTitle, setTransTitle] = useState(selectedlibrary?.transformations.length > 0 && selectedlibrary?.transformations[0]?.title ? selectedlibrary?.transformations[0]?.title : "");

  useEffect(() => {
    if (selectedlibrary) {
      if (!selectedTransformation) {
        setSelectedTransformation(selectedlibrary?.transformations.length > 0 && selectedlibrary?.transformations[0]);
      }
      if (!nameChange) {
        setNameChange(selectedlibrary?.name);
      }
      if (!description) {
        setDescription(selectedlibrary?.description);
      }
      if (!transTitle) {
        setTransTitle(selectedlibrary?.transformations.length > 0 && selectedlibrary?.transformations[0]?.title ? selectedlibrary?.transformations[0]?.title : "");
      }
    }
  }, [selectedlibrary]);
  const handleItemClick = (data) => {
    setTransTitle("");
    setIsModalOpen(true);
    setSelectedTransformation(null);
    // if (selectedTransformation && data.id !== selectedTransformation.id) {
    setSelectedTransformation(data);
    setTransTitle(data.title);
    // }
  };
  const handleEdit = async (e, id) => {
    setNameChange(e.target.value);
    // console.log(e.target.value);
    // console.log(id);
    dispatch(changeName(e.target.value, id, description));
  };
  const handleDescriptionChange = async (value, id) => {
    setDescription(value);
    dispatch(changeName(nameChange, id, value));
  };
  const handleExport = () => {
    if (selectedTransformation) {
      const textToExport = selectedTransformation.output || "No content to export";
      downloadTxtFile(textToExport, "transformation_output.txt");
    }
  };
  const handleDelete = () => {
    if (selectedTransformation) {
      dispatch(deleteTransById(selectedTransformation.id));
      // setIsModalOpen(false);
      setSelectedTransformation(null);
    }
  };

  const downloadTxtFile = (textToExport, fileName) => {
    // Replace all HTML tags with line breaks and keep the line breaks in the text
    const plainText = textToExport
      .replace(/<\/?[^>]+(>|$)/g, "") // Remove all HTML tags
      .replace(/<\/p>/g, "\n") // Add a line break where </p> tags were
      .replace(/<\/li>/g, "\n") // Add a line break where </li> tags were
      .replace(/<br\s*\/?>/gi, "\n") // Handle <br> tags as line breaks
      .replace(/\n\s*\n/g, "\n") // Remove any extra newlines
      .trim(); // Trim any leading/trailing spaces or newlines

    const element = document.createElement("a");
    const file = new Blob([plainText], { type: "text/plain;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  const handleCopy = () => {
    if (selectedTransformation) {
      const textToCopy = selectedTransformation.output || "No content to copy";

      // Replace all HTML tags with line breaks and keep the line breaks in the text
      const plainText = textToCopy
        .replace(/<\/?[^>]+(>|$)/g, "") // Remove all HTML tags
        .replace(/<\/p>/g, "\n") // Add a line break where </p> tags were
        .replace(/<\/li>/g, "\n") // Add a line break where </li> tags were
        .replace(/<br\s*\/?>/gi, "\n") // Handle <br> tags as line breaks
        .replace(/\n\s*\n/g, "\n") // Remove any extra newlines
        .trim(); // Trim any leading/trailing spaces or newlines

      const textarea = document.createElement("textarea");
      textarea.value = plainText;
      document.body.appendChild(textarea);

      // Select the text inside the textarea
      textarea.select();
      textarea.setSelectionRange(0, 99999); // For mobile devices

      // Execute the copy command
      document.execCommand("copy");
      document.body.removeChild(textarea);

      alert("Content copied to clipboard!");
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="flex flex-row items-center justify-start gap-2 w-[90px]" onClick={handleCopy}>
          <BiCopy />
          <p className="font-semibold">Copy</p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex flex-row items-center justify-start gap-2 w-[90px]" onClick={handleExport}>
          <BiExport />
          <p className="font-semibold">Export</p>
        </div>
      ),
    },
  ];
  const handleShare = async () => {
    const res = await dispatch(getShareLink(selectedlibrary.id));
    if (res) {
      // navigate(`/share/${res.shareId}`);
      // open in new blank tab
      window.open(`/share/${res.shareId}`, "_blank");
    }
  };
  // Function to move an item up or down
  const moveItem = async (direction, index) => {
    let transformations = [...selectedlibrary.transformations];
    // Assuming transformations are already sorted, but sort again if necessary
    transformations.sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0));
    const positionChange = direction === "up" ? -1 : 1;
    const newIndex = index + positionChange;
    // Check bounds
    if (newIndex < 0 || newIndex >= transformations.length) return;
    // Swap orderNumbers
    [transformations[index].orderNumber, transformations[newIndex].orderNumber] = [transformations[newIndex].orderNumber, transformations[index].orderNumber];
    transformations.sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0));
    const updatePayload = await transformations.map(({ id, orderNumber }) => ({
      id,
      orderNumber,
    }));
    console.log(updatePayload);
    console.log(transformations);
    try {
      dispatch(reOrderTransformation(updatePayload, { ...selectedlibrary, transformations }));
    } catch (error) {
      console.error("Failed to update order", error);
    }
  };

  const deleteItem = async (index) => {
    let transformations = [...selectedlibrary.transformations];

    const deletedItems = transformations.splice(index, 1);

    transformations.forEach((transformation, i) => {
      transformation.orderNumber = i + 1; // Update orderNumber based on index
    });

    const updatePayload = transformations.map(({ id, orderNumber }) => ({
      id,
      orderNumber,
    }));

    const deletedId = deletedItems[0]?.id;
    console.log({
      updatePayload,
      deletedId,
    });
    // Send updatePayload and deletedId to the backend
    try {
      await dispatch(deleteTransformationOrder(updatePayload, deletedId)); // Adjust this call as per your actual API function
      await dispatch(getOneLibrary(selectedlibrary.id));
    } catch (error) {
      console.error("Failed to update after deletion", error);
    }
  };
  const handleTitleChange = async (id, newValue) => {
    setTransTitle(newValue);
    dispatch(changeTransName(id, newValue));
  };
  return (
    <>
      {loading ? (
        <MainLoader />
      ) : (
        <>
          <div className="flex items-center justify-between px-2 mt-8 md:justify-between md:px-20">
            <div className="flex items-center justify-center gap-2">
              <MdArrowBackIos className="font-semibold text-[24px] cursor-pointer" onClick={() => navigate("/notes")} />
              <span className="text-lg font-medium">Outputs</span>
            </div>
            <div className="flex justify-center items-center space-x-2">
              {selectedlibrary?.shareId ? (
                <>
                  <div
                    onClick={() => {
                      navigate(`/share/${selectedlibrary.shareId}`);
                    }}
                    className="flex gap-1 cursor-pointer w-fit px-4 h-[32px] bg-gray-100 border-gray-200 text-black font-semibold items-center justify-center rounded-[10px]">
                    <RiEyeLine className="cursor-pointer" />
                  </div>
                  <div
                    onClick={() => {
                      // copy the share link to clipboard
                      const el = document.createElement("textarea");
                      el.value = `${window.location.origin}/share/${selectedlibrary.shareId}`;
                      document.body.appendChild(el);
                      el.select();
                      el.setSelectionRange(0, 999999999);
                      document.execCommand("copy");
                      document.body.removeChild(el);
                      const textBox = document.getElementById("textBox");
                      textBox.textContent = "Copied!";
                      setTimeout(() => {
                        textBox.textContent = "";
                      }, 2000);
                    }}
                    className="flex gap-1 cursor-pointer w-fit px-4 h-[32px] bg-gray-100 border-gray-200 text-black font-semibold items-center justify-center rounded-[10px]">
                    {/* {copy icon} */}
                    <BiCopy className="cursor-pointer" />
                    <span id="textBox"></span>
                  </div>
                </>
              ) : (
                <div onClick={handleShare} className="flex gap-1 cursor-pointer w-fit px-4 h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
                  Create Shareable Page
                </div>
              )}
              {user && !user?.infiniteCeiling ? (
                <div className="flex gap-1 cursor-pointer w-[68px] h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
                  <BsLightningCharge className="font-semibold" />
                  <span>{Math.max(0, user.monthlyCreditCeiling - user.currentMonthUsage)}</span>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
          <div className="flex flex-col px-5 mt-6 md:px-20">
            <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 ">
              {/* left side */}
              <div className="w-full overflow-hidden ">
                {selectedlibrary ? (
                  <div className={`bg-white md:h-auto rounded-xl border border-[#EAEAEA] w-full h-auto mb-3 flex flex-col items-center ${!false && " justify-between gap-0"}`}>
                    <div className="flex items-center justify-start w-full ">
                      <svg width="41" height="41" className="m-3" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="41" height="41" rx="5" fill="#F2F5F7" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.9024 13.2723C17.7243 12.4577 18.8389 12 20.0012 12C21.1634 12 22.2781 12.4577 23.0999 13.2723C23.9218 14.087 24.3835 15.1918 24.3835 16.3439V21.0038C24.3835 22.1558 23.9218 23.2607 23.0999 24.0754C22.2781 24.89 21.1634 25.3477 20.0012 25.3477C18.8389 25.3477 17.7243 24.89 16.9024 24.0754C16.0806 23.2607 15.6189 22.1558 15.6189 21.0038V16.3439C15.6189 15.1918 16.0806 14.087 16.9024 13.2723ZM20.0012 13.6981C19.2933 13.6981 18.6143 13.9769 18.1138 14.4731C17.6132 14.9692 17.332 15.6422 17.332 16.3439V21.0038C17.332 21.7055 17.6132 22.3784 18.1138 22.8746C18.6143 23.3708 19.2933 23.6496 20.0012 23.6496C20.7091 23.6496 21.388 23.3708 21.8886 22.8746C22.3892 22.3784 22.6704 21.7055 22.6704 21.0038V16.3439C22.6704 15.6422 22.3892 14.9692 21.8886 14.4731C21.388 13.9769 20.7091 13.6981 20.0012 13.6981ZM11.7333 21.3285C12.2014 21.261 12.6362 21.5824 12.7043 22.0464C12.9594 23.784 13.8371 25.3724 15.1768 26.521C16.5165 27.6697 18.2286 28.3019 20 28.3019C21.7714 28.3019 23.4835 27.6697 24.8232 26.521C26.1629 25.3724 27.0406 23.784 27.2957 22.0464C27.3638 21.5824 27.7986 21.261 28.2667 21.3285C28.7348 21.396 29.0591 21.827 28.991 22.291C28.6766 24.4323 27.5949 26.3898 25.9439 27.8053C24.2929 29.2209 22.183 30 20 30C17.817 30 15.7071 29.2209 14.0561 27.8053C12.4051 26.3898 11.3234 24.4323 11.009 22.291C10.9409 21.827 11.2652 21.396 11.7333 21.3285Z"
                          fill="#A9BED0"
                        />
                      </svg>
                      <div className="w-full mr-2 py-2">
                        <div className="flex w-full items-center">
                          <BiPencil className="text-neutral-400 text-xs" />
                          <input
                            className="w-full text-base font-semibold focus:outline-none px-1"
                            value={nameChange}
                            disabled={false}
                            onChange={(e) => handleEdit(e, selectedlibrary.id)}
                            placeholder="Enter title of this note.."
                          />
                        </div>
                        <div className="flex w-full items-center">
                          <BiPencil className="text-neutral-400 text-xs mb-auto mt-1" />
                          <textarea
                            value={description}
                            aria-multiline={true}
                            rows={description && description.length == 0 ? 1 : 3}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.rows = e.target.rows + 1;
                              }
                            }}
                            placeholder="Enter a description..."
                            onChange={(e) => handleDescriptionChange(e.target.value, selectedlibrary.id)}
                            className="text-sm px-1 pb-0.5 text-gray-400 w-full resize-none focus:outline-none"
                          />
                        </div>
                        <p className="text-[#C4C4C4] text-xs">{selectedlibrary ? new Date(selectedlibrary.createdAt).toDateString() : "No date available"}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <> </>
                )}
                <div
                  onClick={() => {
                    navigate("/templates");
                  }}
                  className="flex gap-1 cursor-pointer w-fit px-5 h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px] mb-3">
                  <span>Add a template</span>
                </div>
                {selectedlibrary &&
                  selectedlibrary.transformations &&
                  [...selectedlibrary.transformations] // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0)) // Sort by orderNumber, assuming null/undefined as 0
                    .map((data, index) => (
                      <div
                        key={data.id}
                        className={`  rounded-[10px]  border border-[#EAEAEA] h-[90px] w-full md:w-full flex flex-col my-2 items-center ${!false && " justify-between gap-0"} ${
                          selectedTransformation && selectedTransformation?.id == data.id ? "bg-slate-100" : "bg-white"
                        } ${data.status == "processing" ? "cursor-not-allowed opacity-50" : "cursor-pointer"} `}>
                        <div className="flex items-center justify-start w-full py-1 ">
                          <div className="w-full pb-4 flex h-[80px] items-center" onClick={() => handleItemClick(data)}>
                            <p className="m-3 bg-[#b3c6d6] h-fit">{data.useCase.icon}</p>
                            <div className="flex items-center justify-between w-full gap-3  md:gap-96">
                              <div>
                                {/* <input
                                className="w-full text-base font-semibold"
                                value={`${data.useCase && data.useCase.name ? data.useCase.name : "No Use Case Name"}`}
                                disabled={true}
                                onChange={(e) => handleEdit(e, data.id)}
                              /> */}
                                <div className="flex items-center gap-1">
                                  <p>{data.useCase && data.useCase.name ? data.useCase.name : "No Use Case Name"}</p>
                                  <p className="text-sm text-gray-500"> {data?.title && "- " + data?.title}</p>
                                </div>

                                <p className="text-[#C4C4C4] text-xs">
                                  {data.createdAt && data.createdAt && new Date(data.createdAt).toDateString() ? new Date(data.createdAt).toDateString() : "No date available"}
                                </p>
                                {data.status == "processing" && <p className="text-[#000] text-xs">Processing...</p>}
                              </div>
                            </div>
                          </div>
                          <div className="mb-auto">
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: "3",
                                    label: (
                                      <div className="flex flex-row items-center justify-start gap-2 w-[90px]  " onClick={() => deleteItem(index)}>
                                        <RiDeleteBinLine className="mt-0.5" />
                                        <p className="font-semibold">Delete</p>
                                      </div>
                                    ),
                                  },
                                  index > 0 && {
                                    key: "1",
                                    label: (
                                      <div className="flex flex-row items-center justify-start gap-2 w-[90px]" onClick={() => moveItem("up", index)}>
                                        <IoArrowUpOutline className="mt-0.5" />
                                        <p className="font-semibold">Up</p>
                                      </div>
                                    ),
                                  },
                                  index < selectedlibrary.transformations.length - 1 && {
                                    key: "2",
                                    label: (
                                      <div className="flex flex-row items-center justify-start gap-2 w-[90px]" onClick={() => moveItem("down", index)}>
                                        <IoArrowDownOutline className="mt-0.5" />
                                        <p className="font-semibold">Down</p>
                                      </div>
                                    ),
                                  },
                                ],
                              }}
                              className=""
                              placement="bottom">
                              <MdMoreVert size={27} color="#ada9a9" className="px-1 mt-2" />
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>

              {/* right side column */}
              <div>
                {/* {selectedTransformation !== null && ( */}
                {selectedTransformation && (
                  <input
                    type="text"
                    placeholder="Custom Output Name"
                    className="w-full border rounded-xl h-12 px-3 mb-4"
                    value={transTitle ? transTitle : ""}
                    onChange={(e) => handleTitleChange(selectedTransformation.id, e.target.value)}
                  />
                )}
                {selectedTransformation && (
                  <div className={`bg-white h-auto rounded-xl border  border-[#EAEAEA]  w-full  flex flex-col  items-center ${!false && " justify-between gap-0"}`}>
                    {/* selected library */}

                    <div className="flex items-end justify-end w-full border-b">
                      <Dropdown
                        menu={{
                          items,
                        }}
                        className=""
                        placement="bottom">
                        <svg width="4" height="14" className="z-40 mx-3 my-3 cursor-pointer" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="1.75" cy="1.75" r="1.75" fill="#B0AEAE" />
                          <circle cx="1.75" cy="7" r="1.75" fill="#B0AEAE" />
                          <circle cx="1.75" cy="12.25" r="1.75" fill="#B0AEAE" />
                        </svg>
                      </Dropdown>
                    </div>
                    <QuillEditor text={selectedTransformation?.output} selectedTransformation={selectedTransformation} />
                  </div>
                )}

                {/* )} */}
              </div>
            </div>
          </div>
          {/* <Modal open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}></Modal> */}
        </>
      )}
    </>
  );
};

export default Transformation;
