import React from 'react'

export function Modal(props) {
    const { handleClose } = props
    return (
        <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-[999999] min-h-screnn h-full flex justify-center items-center ' >
            <div className={`bg-[#fff] rounded-md px-7 py-5 relative ${props.width ? props.width : `w-1/3`}`}>
                {props.children}
            </div>
        </div>
    )
}