import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const AutoLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");
    const redirect = params.get("redirect");

    if (accessToken && refreshToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      toast.success("Logged in successfully!");
      if (redirect == undefined || redirect == "" || redirect == null || redirect == "null" || redirect == "undefined") {
        navigate("/home");
      } else {
        navigate(redirect || "/");
      }
    } else {
      toast.error("Invalid login parameters.");
      navigate("/signin"); // Redirect to sign-in page if parameters are missing
    }
  }, [navigate]);

  return <div>Logging you in...</div>;
};

export default AutoLogin;
