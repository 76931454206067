import React, { useEffect, useRef, useState } from "react";
import { useTimer } from "react-timer-hook";
const Timer = ({ expiryTimestamp, isRecording }) => {
  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({ expiryTimestamp, onExpire: () => console.warn("onExpire called") });
  useEffect(() => {
    if (expiryTimestamp && isRecording) {
      console.log(expiryTimestamp);
      start();
    } else {
      pause();
    }
  }, [expiryTimestamp, isRecording]);
  return (
    <>
      {minutes ? minutes : 5}:{seconds ? seconds : "00"}
    </>
  );
};

export default Timer;
