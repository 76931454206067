import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../../components/MainLoader";
import { MdArrowBackIos } from "react-icons/md";
import { BsLightningCharge } from "react-icons/bs";
import logo from "../../assets/logo.png";
import { message } from "antd";
import axios from "../../redux/actions/AxiosInstance/authenticated";
import { getUseCases } from "../../redux/actions/auth";
const items = [
  {
    label: "English",
    value: "en",
    key: "1",
  },
  {
    label: "English (US)",
    value: "en-US",
    key: "2",
  },
  {
    label: "English (Australia)",
    value: "en-AU",
    key: "3",
  },
  {
    label: "English (UK)",
    value: "en-GB",
    key: "4",
  },
  {
    label: "English (New Zealand)",
    value: "en-NZ",
    key: "5",
  },
  {
    label: "English (India)",
    value: "en-IN",
    key: "6",
  },
  {
    label: "French",
    value: "fr",
    key: "7",
  },
  {
    label: "French (Canada)",
    value: "fr-CA",
    key: "8",
  },
  {
    label: "German",
    value: "de",
    key: "9",
  },
  {
    label: "Hindi",
    value: "hi",
    key: "10",
  },
  {
    label: "Hindi (Latin)",
    value: "hi-Latn",
    key: "11",
  },
  {
    label: "Portuguese",
    value: "pt",
    key: "12",
  },
  {
    label: "Portuguese (Brazil)",
    value: "pt-BR",
    key: "13",
  },
  {
    label: "Spanish",
    value: "es",
    key: "14",
  },
  {
    label: "Spanish (Latin America)",
    value: "es-419",
    key: "15",
  },
];

const Templates = () => {
  const navigate = useNavigate();
  const { selectedlibrary } = useSelector((state) => state.library);
  const { useCases, selectedUseCaseId, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [selectedCats, setSelectedCats] = useState(["All"]);
  // const [selectedCats, setSelectedCats] = useState(["All"]);
  const [categories, setCategories] = useState([]);
  const [personas, setPersonas] = useState([]);

  const selectUseCase = async (data) => {
    await dispatch({ type: "SET_SELECTED_USECASES", payload: data });
    // if (selectedlibrary) {
    navigate("/templates/edit");
    // } else {
    //   navigate(`/view-template-details/${data.id}`);
    // }
  };

  const handleEdit = async () => {
    console.log("edit the title");
  };

  const handleBack = () => {
    navigate("/home");
  };
  useEffect(() => {
    // fetch route to get all categories
    // setLoading(true);
    dispatch(getUseCases());
    const fetchCategories = async () => {
      try {
        let tmp = ["All"];
        const response = await axios.get("/use-cases/get-all-categories");
        const arr = response.data.categories;
        tmp = [{ name: "All", id: 0 }, ...arr];
        setPersonas(response.data.personas);
        setCategories(tmp);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);
  const [disabled, setDisabled] = useState(false);
  const handleStartAutoGen = async () => {
    try {
      if (!selectedlibrary) return message.error("Please select a note first.");
      const selectedP = document.getElementById("personaa").value;
      const language = document.getElementById("language").value;
      console.log(selectedP);
      setDisabled(true);
      // return;
      const response = await axios.post("/library/gen-share-output", {
        id: selectedlibrary.id,
        personaId: Number(selectedP),
        language,
      });
      const data = response.data;
      if (data.status) {
        message.success("Magic generation ongoing, please find your outputs in Notes");
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
    setDisabled(false);
  };
  return (
    <>
      {loading ? (
        <MainLoader />
      ) : (
        <>
          <div className="w-full md:w-2/3 flex items-center justify-between px-2 md:px-0 mx-auto mt-8 md:mt-12">
            <div className="flex items-center justify-center gap-2 ml-0 md:-ml-24 ">
              <MdArrowBackIos className="font-semibold text-[24px]   cursor-pointer" onClick={handleBack} />
              <img src={logo} className="md:hidden" />
            </div>
            {user && !user?.infiniteCeiling ? (
              <div className="flex gap-1 cursor-pointer w-[68px] h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
                <BsLightningCharge className="font-semibold" />
                <span>{Math.max(0, user.monthlyCreditCeiling - user.currentMonthUsage)}</span>
              </div>
            ) : (
              <div />
            )}
          </div>

          <div className="flex flex-col items-center justify-center w-full mt-6 ">
            {/* hide this for null selectedLibrary */}
            {selectedlibrary ? (
              <div className={`bg-white md:h-auto rounded-xl border border-[#EAEAEA] w-full h-[71px] md:w-2/3 flex flex-col mx-2 items-center ${!false && " justify-between gap-0"}`}>
                <div className="flex items-center justify-start w-full ">
                  <svg width="41" height="41" className="m-3" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="41" height="41" rx="5" fill="#F2F5F7" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.9024 13.2723C17.7243 12.4577 18.8389 12 20.0012 12C21.1634 12 22.2781 12.4577 23.0999 13.2723C23.9218 14.087 24.3835 15.1918 24.3835 16.3439V21.0038C24.3835 22.1558 23.9218 23.2607 23.0999 24.0754C22.2781 24.89 21.1634 25.3477 20.0012 25.3477C18.8389 25.3477 17.7243 24.89 16.9024 24.0754C16.0806 23.2607 15.6189 22.1558 15.6189 21.0038V16.3439C15.6189 15.1918 16.0806 14.087 16.9024 13.2723ZM20.0012 13.6981C19.2933 13.6981 18.6143 13.9769 18.1138 14.4731C17.6132 14.9692 17.332 15.6422 17.332 16.3439V21.0038C17.332 21.7055 17.6132 22.3784 18.1138 22.8746C18.6143 23.3708 19.2933 23.6496 20.0012 23.6496C20.7091 23.6496 21.388 23.3708 21.8886 22.8746C22.3892 22.3784 22.6704 21.7055 22.6704 21.0038V16.3439C22.6704 15.6422 22.3892 14.9692 21.8886 14.4731C21.388 13.9769 20.7091 13.6981 20.0012 13.6981ZM11.7333 21.3285C12.2014 21.261 12.6362 21.5824 12.7043 22.0464C12.9594 23.784 13.8371 25.3724 15.1768 26.521C16.5165 27.6697 18.2286 28.3019 20 28.3019C21.7714 28.3019 23.4835 27.6697 24.8232 26.521C26.1629 25.3724 27.0406 23.784 27.2957 22.0464C27.3638 21.5824 27.7986 21.261 28.2667 21.3285C28.7348 21.396 29.0591 21.827 28.991 22.291C28.6766 24.4323 27.5949 26.3898 25.9439 27.8053C24.2929 29.2209 22.183 30 20 30C17.817 30 15.7071 29.2209 14.0561 27.8053C12.4051 26.3898 11.3234 24.4323 11.009 22.291C10.9409 21.827 11.2652 21.396 11.7333 21.3285Z"
                      fill="#A9BED0"
                    />
                  </svg>
                  <div>
                    <p className="text-base font-bold " contentEditable="true" onBlur={(e) => handleEdit(e)}>
                      {selectedlibrary && selectedlibrary.name ? selectedlibrary?.name : "Untitled"}
                    </p>
                    <p className="text-[#C4C4C4] text-xs">{selectedlibrary ? new Date(selectedlibrary.createdAt).toDateString() : "No date available"}</p>
                  </div>
                </div>
              </div>
            ) : (
              <> </>
            )}
            <div className="flex px-2 flex-col w-full md:w-2/3 gap-2 mt-6">
              <span className="font-semibold font-red-hat-display">Browse categories</span>
              {/* taking data from utils/styles */}
              <div className="flex flex-wrap items-center w-full gap-3">
                {categories &&
                  categories.map((data) => (
                    <p
                      onClick={() => {
                        let temp = selectedCats;

                        if (data.name === "All") {
                          // If "All" is clicked, set the selection to only "All"
                          temp = ["All"];
                        } else {
                          // Handling other categories when "All" is not the target
                          // Remove "All" from selection if it's there
                          temp = temp.filter((cat) => cat !== "All");

                          // Toggle the clicked category
                          if (temp.includes(data.name)) {
                            temp = temp.filter((cat) => cat !== data.name);
                          } else {
                            temp.push(data.name);
                          }
                        }
                        // Update the state to reflect changes
                        setSelectedCats(temp);
                      }}
                      className={`px-3 py-1 mb-2 font-semibold border rounded-lg cursor-pointer sm:mb-0  ${
                        selectedCats.includes(data.name) ? "border-[#4f47dc] bg-[#4f47dc] text-white " : " border-gray-100 "
                      }`}>
                      {data.name}
                    </p>
                  ))}
              </div>
            </div>
            {/* features */}
            <div className="flex items-start justify-start w-full px-2 mt-3 md:mt-6 font-semibold md:w-2/3 font-red-hat-display">Templates</div>
            <div className="w-full md:w-2/3 ">
              {/* card */}
              <div className="w-full shadow-sm mx-2 p-2 border rounded-md cursor-pointer hover:shadow-md my-4 md:my-8">
                <div className="flex flex-col items-center justify-center w-full p-2">
                  <p className="text-base font-semibold w-full text-left">Magic Generator</p>
                  <p className="w-full text-sm text-left font-normal text-gray-600 font-red-hat-display">Generate all the relevant outputs based on your content in 1 click.</p>
                </div>
                {/* div with select and button to start in row */}
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-fit p-2">
                  <div className="flex flex-row items-center justify-start w-full">
                    <p className="text-sm font-semibold text-left font-red-hat-display">Profile:</p>
                    <div className=" ml-2 bg-white px-1 border rounded-full hover:shadow">
                      <select name="" id="personaa" className=" bg-white px-2 py-2 cursor-pointer rounded-full">
                        {/* persona map */}
                        {personas.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-fit p-2">
                  <div className="flex flex-row items-center justify-start w-full">
                    <p className="text-sm font-semibold text-left font-red-hat-display">Language:</p>
                    <div className=" ml-2 bg-white px-1 border rounded-full hover:shadow">
                      <select name="" id="language" className=" bg-white px-2 py-2 cursor-pointer rounded-full">
                        {/* persona map */}
                        {items.map((data) => (
                          <option value={data.label}>{data.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    onClick={handleStartAutoGen}
                    disabled={disabled}
                    className="bg-[#4f47dc] text-white font-semibold rounded-md px-4 py-1 mt-3 md:mt-0 md:ml-6 hover:shadow-lg hover:opacity-100">
                    {disabled ? "Loading" : "Start"}
                  </button>
                </div>
              </div>
              {/* taking data from utils/styles */}

              <div className="flex-wrap grid grid-cols-2 md:grid-cols-3 gap-2 mx-2 justify-center px-auto text-center md:gap-7 mt-2 mb-24 cursor-pointer md:flex-row">
                {useCases &&
                  user && // Add a check for user object
                  user.planType !== null &&
                  useCases
                    .filter((data) => data.active)
                    // .filter((data) => (user.planType === "basic" ? data.membershipType === "basic" : true))
                    .filter((data) => {
                      if (selectedCats.includes("All") || selectedCats.length === 0) {
                        return true;
                      }
                      // Assuming 'data.categories' holds the categories of the useCase
                      return data.categories.some((cat) => selectedCats.includes(cat));
                    })
                    .map((data) => (
                      <div
                        key={data.id}
                        onClick={() => selectUseCase(data)}
                        className={`flex flex-col items-start border hover:shadow-md justify-start md:h-auto w-full md:w-full p-4 borderborder-gray-100 border-solid rounded-md shadow-sm `}>
                        <div className="flex items-center justify-between w-full py-3">
                          <p>{data.icon}</p>
                        </div>
                        <h2 className="font-semibold text-left font-red-hat-display">{data.name}</h2>
                        <p className="text-xs text-left font-normal text-gray-600 font-red-hat-display">{data.description}</p>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Templates;
