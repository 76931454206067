import React, { useEffect, useState } from "react";
import axios from "../redux/actions/AxiosInstance/authenticated";
import { useSelector } from "react-redux";
import { BsStopwatch } from "react-icons/bs";
import { useLocation } from "react-router-dom"; // Import useLocation
import { getUserInfo } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { message, Modal } from "antd";
const Plans = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const location = useLocation(); // Hook to get location object
  const { user } = useSelector((state) => state.auth);
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");
  const planTab = queryParams.get("plan");
  const [plan, setPlan] = useState(null);
  const [planType, setPlanType] = useState("");
  useEffect(() => {
    if (planTab) {
      setPlan(planTab);
    } else {
      if (user) setPlan(String(user.planType).split("-")[1]);
    }
  }, [planTab, user]);
  const handlesubscription = async (pt) => {
    if (user.planType == pt) {
      message.info("You are already on this plan");
      return;
    }
    const btns = document.getElementsByTagName("button");
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = true;
      btns[i].style.opacity = 0.5;
    }
    message.loading("Processing...");
    try {
      const response = await axios.post(`/stripe/create-checkout-session`, {
        plantype: pt,
      });
      dispatch(getUserInfo());
      if (response.data.status) {
        if (response.data.url) window.location = response.data.url;
        message.success(response.data.message ? response.data.message : "Subscription updated successfully");
      } else {
        message.error(response.data.message ? response.data.message : "An error occured");
      }
    } catch (error) {
      console.log(error.message);
      message.error("An error occured");
    }
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = false;
      btns[i].style.opacity = 1;
    }
  };
  const showModal = (pt) => {
    if (user.planStatus == "inactive") {
      handlesubscription(pt);
      return;
    }

    if (user.planType == pt) {
      message.info("You are already on this.");
      return;
    }
    if (String(user.planType).includes("lifetime") && !String(pt).includes("lifetime")) {
      message.info("You are on a lifetime plan.");
      return;
    }
    if (String(user.planType).includes("pro-lifetime")) {
      message.info("You are on a pro lifetime plan.");
      return;
    }
    setOpen(true);
    setPlanType(pt);
    // handlesubscription(pt);
    // handlesubscription(plantype);
    // set time out and then set
  };
  const cancelSub = async () => {
    const btns = document.getElementsByTagName("button");
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = true;
      btns[i].style.opacity = 0.5;
    }
    message.loading("Processing...");
    try {
      const response = await axios.post(`/stripe/cancel-subscription`);
      dispatch(getUserInfo());
      if (response.data.status) {
        message.success(response.data.message ? response.data.message : "Subscription updated successfully");
      } else {
        message.error(response.data.message ? response.data.message : "An error occured");
      }
    } catch (error) {
      console.log(error.message);
      message.error("An error occured");
    }
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = false;
      btns[i].style.opacity = 1;
    }
  };
  return (
    user && (
      <div className="bg-white p-8 mb-8 w-full md:w-[90%] lg:w-[85%] xl:w-[75%]">
        <Modal
          title=""
          open={open}
          onOk={() => {
            handlesubscription(planType);
            setOpen(false);
          }}
          onCancel={() => {
            setPlanType("");
            setOpen(false);
          }}
          okText="Yes"
          okButtonProps={{ className: "btn-primary hover:btn-secondary border-0 hover:opacity-70" }}
          cancelText="Cancel">
          <p>Are you sure you want to update your plan to {planType}</p>
        </Modal>
        <Modal
          title=""
          open={openCancel}
          onOk={() => {
            cancelSub();
            setOpenCancel(false);
          }}
          onCancel={() => {
            setOpenCancel(false);
          }}
          okText="Yes"
          okButtonProps={{ className: "btn-primary bg-red-600 hover:btn-secondary border-0 hover:opacity-70" }}
          cancelText="Cancel">
          <p>Are you sure you want to cancel your plan?</p>
        </Modal>
        <div className="w-full">
          <div className="flex justify-center items-start space-x-10 ml-0 md:ml-10 lg:ml-20">
            {/* Starter Plan */}

            <div className="border rounded-lg shadow p-6 pb-3 w-full md:w-1/3">
              <h3 className="text-lg font-bold text-purple-700 mb-4">Starter plan</h3>
              <ul
                className="mb-6"
                style={{
                  listStyleType: "none",
                }}>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">420 mins of audio per month</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Upload audio, YouTube, Google Meet, mic recording</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Unlimited audio length</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Unlimited Audio/Video files</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Edit output & transcript</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Magic generator</div>
                </li>
              </ul>
            </div>

            {/* Pro Plan */}
            <div className="hidden md:block  border rounded-lg shadow p-6 pb-3 w-1/3">
              <h3 className="text-lg font-bold text-purple-700 mb-4">Pro plan</h3>
              <ul
                className="mb-6"
                style={{
                  listStyleType: "none",
                }}>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">900 mins of audio per month</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Upload audio, YouTube, Google Meet, mic recording</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Unlimited audio length</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Unlimited Audio/Video files</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Edit output & transcript</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Magic generator</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="w-1/2 md:w-[21%] py-4 text-center">
              <div className="font-semibold">Monthly</div>
              <div className="my-8 font-semibold">Yearly pass</div>
              {/* <div className="font-semibold">
                <span>Lifetime pass</span>

                <p className="text-xs -mt-1 italic text-red-500">Limited time offer</p>
                <p className="text-lg text-center">
                  <BsStopwatch className="text-center mx-auto" />
                </p>
              </div> */}
            </div>
            <div className="w-1/2 md:w-1/3 text-center">
              <div>
                <button className="btn-primary mt-4 my-auto w-36" type="button" onClick={() => showModal("starter-monthly")}>
                  $45 / month
                </button>
              </div>
              <div className="flex flex-col items-center justify-center">
                <button className="btn-primary mt-6 my-auto w-36" type="button" onClick={() => showModal("starter-yearly")}>
                  $159 / year
                </button>
                <p className=" text-red-500 font-semibold text-xs text-center mt-1">Save $381</p>
              </div>
              {/* <div>
                <button className="btn-primary mt-6 my-auto w-36" type="button" onClick={() => showModal("starter-lifetime")}>
                  $299 - Forever
                </button>
              </div> */}
            </div>
            <div className="hidden md:block w-1/3 text-center">
              <div>
                <button className="btn-primary mt-4 my-auto w-36" type="button" onClick={() => showModal("pro-monthly")}>
                  $99 / month
                </button>
              </div>
              <div className="flex flex-col justify-center items-center">
                <button className="btn-primary mt-6 my-auto w-36" type="button" onClick={() => showModal("pro-yearly")}>
                  $299 / year
                </button>
                <p className=" text-red-500 font-semibold text-xs text-center mt-1">Save $889</p>
              </div>
              {/* <div>
                <button className="btn-primary mt-6 my-auto w-36" type="button" onClick={() => showModal("pro-lifetime")}>
                  $579 - Forever
                </button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="block md:hidden">
          <div className="flex justify-center items-start space-x-10 ml-0 md:ml-10 lg:ml-20">
            <div className="block md:hidden  border rounded-lg shadow p-6 pb-3 w-full">
              <h3 className="text-lg font-bold text-purple-700 mb-4">Pro plan</h3>
              <ul
                className="mb-6"
                style={{
                  listStyleType: "none",
                }}>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">900 mins of audio per month</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Upload audio, YouTube, Google Meet, mic recording</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Unlimited audio length</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Unlimited Audio/Video files</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Edit output & transcript</div>
                </li>
                <li className="flex">
                  <div>✔️</div>
                  <div className="ml-1">Magic generator</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="w-1/2 md:w-[21%] py-4 text-center">
              <div className="font-semibold">Monthly</div>
              <div className="my-8 font-semibold">Yearly pass</div>
              {/* <div className="font-semibold">
                <span>Lifetime pass</span>

                <p className="text-xs -mt-1 italic text-red-500">Limited time offer</p>
                <p className="text-lg text-center">
                  <BsStopwatch className="text-center mx-auto" />
                </p>
              </div> */}
            </div>

            <div className="block md:hidden w-1/2 text-center">
              <div>
                <button className="btn-primary mt-4 my-auto w-36">$99 / month</button>
              </div>
              <div className="flex flex-col items-center justify-center">
                <button className="btn-primary mt-6 my-auto w-36">$299 / year</button>
                <p className=" text-red-500 font-semibold text-xs text-center mt-1">Save $889</p>
              </div>

              {/* <div>
                <button className="btn-primary mt-6 my-auto w-36">$579 - Forever</button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-col md:flex-row gap-6 justify-between px-0 md:px-16">
          <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-12 w-full">
            <div className=" ">
              <h3 className="text-base font-bold text-gray-900">My Subscription</h3>
              <p className="text-sm text-gray-500">
                {user.planStatus == "active" ? (
                  <>
                    You are on the <span className="font-semibold">{String(user?.planType.split("-")[0]).toUpperCase() + " " + String(user?.planType.split("-")[1]).toUpperCase()}</span> plan
                  </>
                ) : (
                  <>Inactive</>
                )}
              </p>
            </div>
            {user.planStatus == "active" ? <p className="text-sm text-gray-500">Expires At: {new Date(user?.subExpiresAt).toDateString()}</p> : <></>}
          </div>
          <div className="flex justify-end">
            {user.planStatus == "active" && !String(user.planType).includes("lifetime") ? (
              <button type="button" onClick={() => setOpenCancel(true)} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">
                Cancel
              </button>
            ) : (
              <></>
            )}
            {/* <button className=" text-white px-4 py-2 rounded btn-primary">Upgrade</button> */}
          </div>
        </div>
      </div>
    )
  );
};

export default Plans;
