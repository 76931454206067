import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import { MdArrowBackIos } from "react-icons/md";
import { BsLightningCharge } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MainLoader from "../../components/MainLoader";

const ViewTemplate = () => {
  const { useCases, user } = useSelector((state) => state.auth);
  const { id: selectedUseCaseId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedUseCase, setSelectedUseCase] = useState(null);

  useEffect(() => {
    setLoading(true);

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    const foundUseCase = useCases.find((useCase) => useCase.id.toString() === selectedUseCaseId);

    if (foundUseCase) {
      setSelectedUseCase(foundUseCase);
    } else {
      navigate("/not-found");
    }

    return () => clearTimeout(timeoutId);
  }, [useCases, selectedUseCaseId, navigate]);

  const handleBack = () => {
    navigate("/home");
  };

  return (
    <>
      {loading ? (
        <MainLoader />
      ) : (
        <>
          <div className="flex items-center justify-between px-1 mt-8 md:mt-12 md:px-52">
            <div className="flex items-center justify-center gap-2">
              <MdArrowBackIos className="font-semibold text-[24px] cursor-pointer" onClick={handleBack} />
              <img src={logo} className="md:hidden" alt="Logo" />
            </div>
            {user && !user?.infiniteCeiling ? (
              <div className="flex gap-1 cursor-pointer w-[68px] h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
                <BsLightningCharge className="font-semibold" />
                <span>{Math.max(0, user.monthlyCreditCeiling - user.currentMonthUsage)}</span>
              </div>
            ) : (
              <div />
            )}
          </div>

          <div className="flex flex-col px-4 md:m-5 mt-6 md:px-52 md:mt-12">
            {selectedUseCase && (
              <>
                <div className="flex flex-row gap-2">
                  <span className="text-[18px] font-semibold">Emoji : </span>
                  <span>{selectedUseCase.icon}</span>
                </div>
                <div className="flex flex-row gap-2">
                  <span className="text-[18px] font-semibold">Name : </span>
                  <span>{selectedUseCase.name}</span>
                </div>
                <div className="flex flex-row gap-2">
                  <span className="text-[18px] font-semibold">Description :</span>
                  <span>{selectedUseCase.description}</span>
                </div>
              </>
            )}

            <span className="mt-6 text-lg font-bold">Field Details</span>

            {selectedUseCase &&
              selectedUseCase.fields.map((field, index) => (
                <div key={index}>
                  <div className="flex flex-row gap-2 ">
                    <span className="text-[18px] font-semibold">{field.label}</span>
                  </div>
                  <div className="flex flex-row gap-2 mt-2 ">
                    <span className="text-[18px] font-semibold">{field.placeholder}</span>
                  </div>
                </div>
              ))}
            <>
              <span className="mt-6 mb-2 text-lg font-bold">Sample Output</span>

              {selectedUseCase && selectedUseCase.name === "Summary" && (
                <div className="w-full p-2 border border-gray-100 rounded-md shadow-md md:w-2/3">
                  <span className="font-bold">Summary</span>
                  <p className="text-md">
                    Research is a methodical exploration aimed at acquiring insights. It involves systematic data collection, rigorous analysis, and contributes to knowledge, innovation, and
                    problem-solving. The process varies across disciplines but universally propels understanding and advancements.
                  </p>
                </div>
              )}

              {selectedUseCase && selectedUseCase.name === "Post Coversion" && (
                <div className="w-full p-2 border border-gray-100 rounded-md shadow-md md:w-2/3">
                  <span className="font-bold">Post Coversion</span>
                  <p className="text-md">
                    🔍 Research unveils mysteries! 📊 From systematic investigation to innovative breakthroughs, it's the key to advancing knowledge and understanding in diverse fields. 💡 #Research
                    #Innovation #KnowledgeAdvancement
                  </p>
                </div>
              )}
              {selectedUseCase && selectedUseCase.name === "Convert to Email" && (
                <div className="w-full p-2 border border-gray-100 rounded-md shadow-md md:w-2/3">
                  <span className="font-bold">Convert to Email</span>
                  <p className="text-md">
                    Subject: Unlocking Insights Through Research 🧠🔍 <br />
                    Dear , <br />
                    I hope this email finds you well. I wanted to emphasize the significance of research in our endeavors. Research, characterized by systematic investigation, meticulous data
                    collection, and insightful analysis, is the cornerstone of knowledge acquisition. Its impact extends beyond mere information gathering—it fuels innovation and propels us forward by
                    addressing critical questions. In various fields, research acts as the guiding force, pushing the boundaries of understanding. As we navigate our projects, let's harness the power
                    of research to unlock new insights, fostering both knowledge and innovation. Looking forward to discussing how we can integrate research more effectively into our initiatives.
                    <br /> Best regards, <br />
                    [Your Full Name]
                    <br />
                    [Your Position] <br />
                    [Your Contact Information]
                  </p>
                </div>
              )}

              {selectedUseCase && selectedUseCase.name === "Translation" && (
                <div className="w-full p-2 border border-gray-100 rounded-md shadow-md md:w-2/3">
                  <span className="font-bold">Translation</span>
                  <p className="text-md">
                    La investigación implica una indagación sistemática, recopilación de datos y análisis para obtener percepciones. Informa el conocimiento, contribuye a la innovación y aborda
                    preguntas para avanzar en la comprensión en diversos campos
                  </p>
                </div>
              )}
            </>
          </div>

          <div className="px-6 mt-4 mb-20 md:mt-4 md:px-56 md:mb-20">
            <button className=" btn-primary" onClick={handleBack}>
              Generate New Content
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ViewTemplate;
