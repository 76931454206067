import React, { useEffect, useState, useCallback } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { changeName, deleteLibraryById, getLibrary } from "../../redux/actions/library";
import { setSelectedLibrary } from "../../redux/actions/library";
import { useNavigate } from "react-router-dom";
import { BiCopy } from "react-icons/bi";
import { BiExport } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Dropdown, message } from "antd";
import MainLoader from "../../components/MainLoader";
import { copyLibrary } from "../../redux/actions/library";
import logo from "../../assets/logo.png";
import { MdArrowBackIos } from "react-icons/md";
import { BsLightningCharge } from "react-icons/bs";
import { debounce } from "lodash";

const MyNotes = () => {
  const { libraries, loading } = useSelector((state) => state.library);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [sortedLibraries, setSortedLibraries] = useState([]);
  const [fFlag, setFFlag] = useState(true);
  useEffect(() => {
    let isFirstRun = true; // Flag to track the first run

    // Function to refresh the library
    const refreshLibrary = () => {
      if (isFirstRun) {
        console.log("Running for the first time");
        isFirstRun = false; // Set the flag to false after the first run
      } else {
        setFFlag(false);
        console.log("Running on interval");
      }
      dispatch(getLibrary(isFirstRun));
    };

    // Call the function immediately to load the library the first time
    refreshLibrary();

    // Set up the interval to refresh the library every 10 seconds
    const intervalId = setInterval(refreshLibrary, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const [inputValues, setInputValues] = useState({}); // Store input values by id

  const debouncedSave = useCallback(
    debounce((id, value, desc) => {
      dispatch(changeName(value, id, desc));
    }, 300),
    [dispatch]
  ); // Debounce API call but keep it responsive to dispatch changes

  const handleEdit = (e, id, desc) => {
    const { value } = e.target;
    setInputValues((prev) => ({ ...prev, [id]: value })); // Update local state immediately for UI responsiveness
    debouncedSave(id, value, desc); // Debounce API calls
  };

  useEffect(() => {
    // sort and filter libraries based on searchInput
    const filteredData = libraries.filter((data) => data.name.toLowerCase().includes(searchInput.toLowerCase()));
    setSortedLibraries(filteredData);
  }, [libraries, searchInput]);
  useEffect(() => {
    if (libraries && fFlag) {
      // Initialize local state with names from the library data
      const newInputValues = libraries.reduce((acc, lib) => {
        acc[lib.id] = lib.name;
        return acc;
      }, {});
      setInputValues(newInputValues);
    }
  }, [libraries]);

  const handleLibraryClick = async (data) => {
    await dispatch(setSelectedLibrary(data));
    navigate(`/home/transcript/${data.id}`);
  };

  const handleTransformation = async (data) => {
    await dispatch(setSelectedLibrary(data));
    console.log(data);
    navigate(`/transformation/${data.id}`);
  };

  const handleDelete = async (id) => {
    const resp = await dispatch(deleteLibraryById(id));
    if (resp) {
      message.success("Record deleted successfully");
    } else {
      console.log("Error deleting record");
    }
  };

  const handleBack = () => {
    navigate("/home");
  };

  const handleCopy = async (id) => {
    await dispatch(copyLibrary(id));
    dispatch(getLibrary());
    console.log(`Library with ID ${id} copied successfully.`);
  };

  const downloadTxtFile = (textToExport, fileName) => {
    const element = document.createElement("a");
    const file = new Blob([textToExport], { type: "text/plain;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleExport = (data) => {
    const textToExport = data.text;
    console.log(textToExport);
    // Export as TXT
    downloadTxtFile(textToExport, "transcript.txt");
  };
  const debouncedChangeHandler = debounce((value, id, desc) => {
    dispatch(changeName(value, id, desc));
  }, 300); // waits 300ms after the last change to call the API

  return (
    <>
      {loading ? (
        <MainLoader />
      ) : (
        <>
          <div className="flex items-center justify-between px-2 mt-8 md:mt-12 w-full md:w-2/3 mx-auto">
            <div className=""></div>
            {user && !user?.infiniteCeiling ? (
              <div className="flex gap-1 cursor-pointer w-[68px] h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
                <BsLightningCharge className="font-semibold" />
                <span>{Math.max(0, user.monthlyCreditCeiling - user.currentMonthUsage)}</span>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className="flex flex-col items-center justify-center px-3 mt-8 mb-24 md:px-0">
            <div className="bg-white h-auto rounded-[10px] border border-[#EAEAEA] w-full md:w-2/3 flex flex-col items-center ">
              {/* search bar */}
              <div className="relative w-full">
                <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="w-full px-4 py-3 text-gray-400 bg-gray-200 md:px-6 rounded-[9px] focus:outline-none"
                  placeholder="Search Notes"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer md:pr-3">
                  <FiSearch className="text-gray-400" />
                </div>
              </div>
            </div>
            {/* notes */}
            {sortedLibraries.map((data, index) => (
              <div key={data.id} className={`bg-white  rounded-[10px] mt-4 border border-[#EAEAEA] h-[125px] w-full md:w-2/3 flex flex-col   items-center ${!false && " justify-between gap-0"} `}>
                <div className="flex items-center justify-start w-full ">
                  <svg width="41" height="41" className="m-3" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="41" height="41" rx="5" fill="#F2F5F7" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.9024 13.2723C17.7243 12.4577 18.8389 12 20.0012 12C21.1634 12 22.2781 12.4577 23.0999 13.2723C23.9218 14.087 24.3835 15.1918 24.3835 16.3439V21.0038C24.3835 22.1558 23.9218 23.2607 23.0999 24.0754C22.2781 24.89 21.1634 25.3477 20.0012 25.3477C18.8389 25.3477 17.7243 24.89 16.9024 24.0754C16.0806 23.2607 15.6189 22.1558 15.6189 21.0038V16.3439C15.6189 15.1918 16.0806 14.087 16.9024 13.2723ZM20.0012 13.6981C19.2933 13.6981 18.6143 13.9769 18.1138 14.4731C17.6132 14.9692 17.332 15.6422 17.332 16.3439V21.0038C17.332 21.7055 17.6132 22.3784 18.1138 22.8746C18.6143 23.3708 19.2933 23.6496 20.0012 23.6496C20.7091 23.6496 21.388 23.3708 21.8886 22.8746C22.3892 22.3784 22.6704 21.7055 22.6704 21.0038V16.3439C22.6704 15.6422 22.3892 14.9692 21.8886 14.4731C21.388 13.9769 20.7091 13.6981 20.0012 13.6981ZM11.7333 21.3285C12.2014 21.261 12.6362 21.5824 12.7043 22.0464C12.9594 23.784 13.8371 25.3724 15.1768 26.521C16.5165 27.6697 18.2286 28.3019 20 28.3019C21.7714 28.3019 23.4835 27.6697 24.8232 26.521C26.1629 25.3724 27.0406 23.784 27.2957 22.0464C27.3638 21.5824 27.7986 21.261 28.2667 21.3285C28.7348 21.396 29.0591 21.827 28.991 22.291C28.6766 24.4323 27.5949 26.3898 25.9439 27.8053C24.2929 29.2209 22.183 30 20 30C17.817 30 15.7071 29.2209 14.0561 27.8053C12.4051 26.3898 11.3234 24.4323 11.009 22.291C10.9409 21.827 11.2652 21.396 11.7333 21.3285Z"
                      fill="#A9BED0"
                    />
                  </svg>
                  <div className="w-full">
                    <div className="flex items-center justify-between w-full gap-3">
                      <div className="w-full">
                        {/* <p className="text-base font-semibold bg-red-50">
                            {data?.name?.slice(0, 50)}
                            {data?.name?.length > 50 ? "..." : null}
                          </p> */}
                        <input className="text-base font-semibold w-full focus:outline-none" value={inputValues[data.id]} onChange={(e) => handleEdit(e, data.id, data?.description)} />
                        <p className="text-[#C4C4C4] text-xs">{new Date(data.createdAt).toDateString()}</p>
                      </div>
                      <div className="flex items-center ">
                        <div className="flex justify-between ">
                          <div></div>
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: "1",
                                  label: (
                                    <div className="flex flex-row items-center justify-start gap-2 w-[90px]  " onClick={() => handleCopy(data?.id)}>
                                      <BiCopy />
                                      <p className="font-semibold">Copy</p>
                                    </div>
                                  ),
                                },
                                {
                                  key: "2",
                                  label: (
                                    <div className="flex flex-row items-center justify-start gap-2 w-[90px]  " onClick={() => handleExport(data)}>
                                      <BiExport />
                                      <p className="font-semibold">Export</p>
                                    </div>
                                  ),
                                },
                                {
                                  key: "3",
                                  label: (
                                    <div className="flex flex-row items-center justify-start gap-2 w-[90px]  " onClick={() => handleDelete(data?.id)}>
                                      <RiDeleteBinLine />
                                      <p className="font-semibold">Delete</p>
                                    </div>
                                  ),
                                },
                              ],
                            }}
                            className="w-full"
                            placement="bottom">
                            <svg width="10" height="14" className="z-40 cursor-pointer px-2 -mt-4" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="1.75" cy="1.75" r="1.75" fill="#B0AEAE" />
                              <circle cx="1.75" cy="7" r="1.75" fill="#B0AEAE" />
                              <circle cx="1.75" cy="12.25" r="1.75" fill="#B0AEAE" />
                            </svg>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="flex items-center justify-start w-full gap-2 px-2 md:py-0 ">
                  {data?.transformations.length > 0 && (
                    <button className=" btn-primary" onClick={() => handleTransformation(data)} disabled={data?.transformations.length == 0}>
                      Output
                    </button>
                  )}
                  {data.status == "processing" ? (
                    <button className=" btn-primary opacity-70 hover:cursor-default" disabled={true}>
                      Processing...
                    </button>
                  ) : (
                    <button className=" btn-primary" onClick={() => handleLibraryClick(data)}>
                      Original Transcript
                    </button>
                  )}
                </div>
                <p className="flex items-center justify-start w-full  text-gray-400"></p>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default MyNotes;
