import React, { useState } from "react";
import axios from "../../redux/actions/AxiosInstance/authenticated";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
const AddUseCase = () => {
  const navigate = useNavigate();

  const [mainForm, setMainForm] = useState({
    icon: "",
    name: "",
    description: "",
    membershipType: "",
    includedInShare: false,
    sharePrompt: "",
  });

  const handleMainFormChange = (e) => {
    setMainForm({
      ...mainForm,
      [e.target.name]: e.target.value,
    });
  };

  const [prompt, setPrompt] = useState("");

  const [cards, setCards] = useState([
    {
      label: "",
      varName: "untitled",
      input: "text",
      required: false,
      placeholder: "",
    },
  ]);

  const addNewCard = () => {
    setCards([
      ...cards,
      {
        label: "",
        varName: "variable_" + (cards.length + 1),
        input: "text",
        required: false,
        placeholder: "",
      },
    ]);
  };

  const handleMembershipTypeChange = (value) => {
    setMainForm({
      ...mainForm,

      membershipType: value,
    });
  };

  const removeCard = (indexToRemove) => {
    // Create a new array without the card to be removed
    const updatedCards = cards.filter((_, index) => index !== indexToRemove);
    setCards(updatedCards);
  };

  const handleInputChange = (index, event) => {
    const newCards = [...cards];
    newCards[index][event.target.name] = event.target.value;
    setCards(newCards);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let flag = false;
    if (mainForm.icon == "" || mainForm.description == "" || mainForm.name == "" || mainForm.membershipType === "") {
      flag = true;
    }
    if (prompt == "" || prompt == null) {
      flag = true;
    }
    cards.forEach((element) => {
      if (element.input == "" || element.label == "" || element.placeholder == "" || element.varName == "") {
        flag = true;
        return;
      }
    });
    if (flag) {
      alert("Please fill all the fields");
      return;
    }
    const form = {
      ...mainForm,
      prompts: prompt,
      fields: cards,
    };
    const { status, data } = await axios.post("/use-cases/create", {
      data: form,
    });
    if (status == 200) {
      alert("UseCase Created Successfully");
      navigate("/dvp/admin/usecase/");
      window.location.reload();
    }
  };

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleDropdownChange = (val) => {
    console.log(val);
    if (val) {
      setPrompt((prev) => ` ${prev}{{${val}}} `);
    }
  };

  const { Option } = Select;
  return (
    <form onSubmit={handleSubmit} className="w-full space-y-4">
      <div className="grid w-full min-h-screen grid-cols-2 gap-6 px-6 py-6 space-y-4">
        <div className="container p-4 mx-auto space-y-4 bg-white rounded-lg shadow-md h-fit">
          <h1 className="my-2 text-lg font-Poppins ">Add a UseCase</h1>
          <div className="space-y-2">
            <input
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              name="name"
              placeholder="Name"
              value={mainForm.name}
              onChange={handleMainFormChange}
            />
            <input
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              name="icon"
              placeholder="Emoji"
              value={mainForm.icon}
              onChange={handleMainFormChange}
            />

            <textarea
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              name="description"
              placeholder="Description"
              value={mainForm.description}
              onChange={handleMainFormChange}
            />

            <Select
              className="w-full h-12"
              placeholder="select a plan"
              name="membershipType"
              value={mainForm.membershipType} // Set the selected value
              onChange={handleMembershipTypeChange}>
              <Option value="basic">Basic</Option>
              <Option value="pro">Pro</Option>
            </Select>
            <div className="flex justify-start items-center">
              <input
                type="checkbox"
                name="includedInShare"
                id="includedInShare"
                checked={mainForm.includedInShare}
                onChange={(e) => {
                  setMainForm({
                    ...mainForm,
                    includedInShare: e.target.checked,
                  });
                }}
              />
              <label htmlFor="includedInShare" className="ml-2">
                Included in Shareable output
              </label>
            </div>
          </div>

          {cards.map((card, index) => (
            <div key={index} className="p-4 border border-gray-200 rounded-md">
              <div className="my-2">
                <label htmlFor="Label">Label:</label>
                <input
                  className="w-full p-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  name="label"
                  placeholder="Label"
                  value={card.label}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="my-2">
                <label htmlFor="Variable Name">
                  {" "}
                  Variable Name: <span className="text-xs text-gray-500">Without spaces (use underscore for different words. For eg: first_name)</span>{" "}
                </label>
                <input
                  className="w-full p-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  name="varName"
                  placeholder="Variable Name"
                  value={card.varName}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="my-2">
                <label htmlFor="">Input Type:</label>
                <select
                  className="w-full p-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  name="input"
                  value={card.input}
                  onChange={(e) => handleInputChange(index, e)}>
                  <option value="text">Text</option>
                  <option value="textarea">Textarea</option>
                </select>
              </div>
              <div className="flex items-center mb-2">
                <input type="checkbox" name="required" checked={card.required} onChange={(e) => handleInputChange(index, e)} className="mr-2" />
                <label>Required</label>
              </div>
              <div className="my-4">
                <label htmlFor="">Placeholder:</label>
                <input
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  name="placeholder"
                  placeholder="Placeholder"
                  value={card.placeholder}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="flex space-x-4">
                <button type="button" onClick={addNewCard} className="btn-secondary text-[#4E46DC] border-[#4E46DC] border-2 py-2 px-6 ">
                  Add New Field
                </button>
                <button type="button" onClick={() => removeCard(index)} className="btn-secondary text-[#4E46DC] border-[#4E46DC] border-2 py-2 px-6 ">
                  Remove Field
                </button>
              </div>
            </div>
          ))}
          {/* <div className="flex gap-4 "> */}

          {/* <button
              type="button"
              onClick={()=>removeCard(index)}
              className="btn-secondary text-[#4E46DC] border-[#4E46DC] border-2 py-2 px-6 "
            >
              Remove Field
            </button> */}
          {/* </div> */}
        </div>
        <div className="container p-4 mx-auto space-y-4 bg-white rounded-lg shadow-md h-fit">
          <select className="w-full p-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
            <option value="">Select a variable</option>
            {cards.map((card, index) => (
              <option key={index} value={card.varName} onClick={() => handleDropdownChange(card.varName)}>
                {card.varName}
              </option>
            ))}
            <option value="userInput" onClick={() => handleDropdownChange("userInput")}>
              userInput
            </option>
          </select>
          <div className="flex flex-col">
            <label htmlFor="" className="">
              Prompt
            </label>
            <label className="mb-1 text-xs text-gray-500 ">Design your prompt here. This will be sent to the AI for transformation after replacing the variables with thier actual values.</label>
            <textarea className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" value={prompt} onChange={handlePromptChange} placeholder="Prompt" rows={15} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="" className="">
              Default Share Prompt
            </label>
            <label className="mb-1 text-xs text-gray-500 ">
              Design your default prompt here. This will be used to generate shareable output. And this will not include any custom fields only userInput (transcript). This will be sent to the AI for
              transformation after appending the transcript at bottom.
            </label>
            <button
              type="button"
              className="w-fit p-1 px-2 my-2 hover:shadow-sm rounded-full bg-gray-200"
              onClick={() => {
                setMainForm({
                  ...mainForm,
                  sharePrompt: mainForm.sharePrompt + " {{userInput}}",
                });
              }}>
              userInput
            </button>
            <textarea
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={mainForm.sharePrompt}
              onChange={(e) => {
                setMainForm({
                  ...mainForm,
                  sharePrompt: e.target.value,
                });
              }}
              placeholder="Prompt"
              rows={15}
            />
          </div>
          <div className="flex space-x-2">
            <button type="submit" className="px-6 py-2 btn-primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddUseCase;
