import { Outlet, Navigate, useLocation } from "react-router-dom";
import { isExpired } from "react-jwt";

const PrivateRoutes = () => {
  const location = useLocation(); // Hook to access the current location
  let auth = localStorage.getItem("accessToken");
  console.log(auth);
  const isMyTokenExpired = isExpired(auth);
  console.log(isMyTokenExpired);

  if (isMyTokenExpired) {
    localStorage.clear(); // Clear local storage if the token is expired
    const redirectTo = `/signin?redirect=${encodeURIComponent(location.pathname + location.search)}`;
    return <Navigate to={redirectTo} replace />;
  }

  return !isMyTokenExpired ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoutes;
