import React from "react";
import axios from "../redux/actions/AxiosInstance/authenticated";
import { useSelector } from "react-redux";
import Subscription from "../components/Subscription";
import MainLoader from "../components/MainLoader";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/actions/auth";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const handlesubscription = async (planType, interval, priceId) => {
    const data = {};
    try {
      const response = await axios.post(`/stripe/create-checkout-session`, {
        priceId: priceId,
      });
      window.location = response.data.url;
    } catch (error) {
      console.log(error.message);
    }
  };
  if (!user) {
    return <MainLoader />;
  }
  const handleLogout = async () => {
    await dispatch(logOut());
    navigate("/signin");
    window.location.reload();
  };
  return (
    <div className="flex flex-col mt-20  mb-24">
      <img src={"/images/logo.png"} alt="logo" className="text-center w-20 mx-auto -mt-12 mb-6 md:mt-0 md:mb-0" />
      <div className="absolute top-10 right-5 flex flex-row items-center justify-center gap-1 " onClick={handleLogout}>
        <span className={`cursor-pointer `}>Logout</span>
        <FiLogOut className="cursor-pointer " />
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center md:mt-8 ml-0 md:-ml-24">
        <Subscription />
      </div>
    </div>
  );
  return (
    <div className="flex flex-col mt-20 px-4 md:px-28 mb-24">
      {/* <> */}
      <img src={"/images/logo.png"} alt="logo" className="text-center w-20 mx-auto -mt-12 mb-6 md:mt-0 md:mb-0" />

      <div className="flex items-center justify-start md:px-28 md:mt-6 ">
        <span className="text-[20px] font-bold border-b-2 border-blue-600">Pricing Plan's</span>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center md:mt-8 md:px-28 md:gap-28">
        <div className="w-full p-4 mt-4 flex items-center justify-center flex-col border border-gray-200 rounded-[10px] shadow-lg ">
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-2xl font-bold font-Plus text-blue-700">Voice Genie</h2>

            <span className="text-2xl font-bold font-Plus text-blue-700">Basic Plan Subscription</span>
          </div>

          {/* Monthly Plan */}
          <div className="flex items-center justify-center flex-col mt-1 p-2">
            <h3 className="text-xl font-semibold font-Plus">Monthly Plan</h3>
            <span className="text-[20px]">$20.00 / month</span>
            {user.acitveStripe && user.acitveStripe.priceId == process.env.REACT_APP_STARTER_MONTH ? (
              <button onClick={() => handlesubscription("basic", "monthly", process.env.REACT_APP_STARTER_MONTH)} className="btn-primary bg-red-700 border border-red-700 mt-2">
                Cancel
              </button>
            ) : (
              <button onClick={() => handlesubscription("basic", "monthly", process.env.REACT_APP_STARTER_MONTH)} className="btn-primary mt-2">
                Subscribe
              </button>
            )}
          </div>

          {/* Yearly Plan */}
          <div className="flex items-center justify-center flex-col mt-1 p-2">
            <h3 className="text-xl font-semibold font-Plus">Yearly Plan</h3>
            <span className="text-[20px]">$40.00 / year</span>
            {user.acitveStripe && user.acitveStripe.priceId == process.env.REACT_APP_STARTER_YEAR ? (
              <button onClick={() => handlesubscription("basic", "monthly", process.env.REACT_APP_STARTER_MONTH)} className="btn-primary bg-red-700 border border-red-700 mt-2">
                Cancel
              </button>
            ) : (
              <button onClick={() => handlesubscription("basic", "yearly", process.env.REACT_APP_STARTER_YEAR)} className="btn-primary mt-2">
                Subscribe
              </button>
            )}
          </div>
          <div className="flex items-center justify-center flex-col mt-1 p-2">
            <h3 className="text-xl font-semibold font-Plus">Lifetime Plan</h3>
            <span className="text-[20px]">$400.00</span>
            {user.acitveStripe && user.acitveStripe.priceId == process.env.REACT_APP_STARTER_LIFETIME ? (
              <button onClick={() => handlesubscription("basic", "monthly", process.env.REACT_APP_STARTER_LIFETIME)} className="btn-primary bg-red-700 border border-red-700 mt-2">
                Cancel
              </button>
            ) : (
              <button onClick={() => handlesubscription("basic", "yearly", process.env.REACT_APP_STARTER_LIFETIME)} className="btn-primary mt-2">
                Subscribe
              </button>
            )}
          </div>
        </div>

        <div className="w-full p-4 mt-4 flex items-center justify-center flex-col border border-gray-200 rounded-[10px] shadow-lg">
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-2xl font-bold font-Plus text-blue-700">Voice Genie</h2>

            <span className="text-2xl font-bold font-Plus text-blue-700">Pro Plan Subscription</span>
          </div>

          {/* Monthly Plan */}
          <div className="flex items-center justify-center flex-col mt-1 p-2">
            <h3 className="text-xl font-semibold font-Plus">Monthly Plan</h3>
            <span className="text-[20px]">$40.00 / month</span>
            {user.acitveStripe && user.acitveStripe.priceId == process.env.REACT_APP_PRO_MONTH ? (
              <button onClick={() => handlesubscription("basic", "monthly", process.env.REACT_APP_STARTER_MONTH)} className="btn-primary bg-red-700 border border-red-700 mt-2">
                Cancel
              </button>
            ) : (
              <button className="btn-primary mt-2" onClick={() => handlesubscription("pro", "monthly", process.env.REACT_APP_PRO_MONTH)}>
                Subscribe
              </button>
            )}
          </div>

          {/* Yearly Plan */}
          <div className="flex items-center justify-center flex-col mt-1 p-2">
            <h3 className="text-xl font-semibold font-Plus">Yearly Plan</h3>
            <span className="text-[20px]">$80.00 / year</span>

            {user.acitveStripe && user.acitveStripe.priceId == process.env.REACT_APP_PRO_YEAR ? (
              <button onClick={() => handlesubscription("basic", "monthly", process.env.REACT_APP_STARTER_MONTH)} className="btn-primary bg-red-700 border border-red-700 mt-2">
                Cancel
              </button>
            ) : (
              <button className="btn-primary mt-2" onClick={() => handlesubscription("pro", "monthly", process.env.REACT_APP_PRO_YEAR)}>
                Subscribe
              </button>
            )}
          </div>
          <div className="flex items-center justify-center flex-col mt-1 p-2">
            <h3 className="text-xl font-semibold font-Plus">Lifetime Plan</h3>
            <span className="text-[20px]">$800.00</span>
            {user.acitveStripe && user.acitveStripe.priceId == process.env.REACT_APP_PRO_LIFETIME ? (
              <button onClick={() => handlesubscription("basic", "monthly", process.env.REACT_APP_PRO_LIFETIME)} className="btn-primary bg-red-700 border border-red-700 mt-2">
                Cancel
              </button>
            ) : (
              <button className="btn-primary mt-2" onClick={() => handlesubscription("pro", "monthly", process.env.REACT_APP_PRO_LIFETIME)}>
                Subscribe
              </button>
            )}
          </div>
        </div>
      </div>
      {/* </> */}
    </div>
  );
};

export default Billing;
