import React, { useEffect, useState } from "react";
import logo from "../../assets/Group.png";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Subscription from "../../components/Subscription";
import Credits from "../../components/Credits";
import axios from "../../redux/actions/AxiosInstance/authenticated";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/auth";
import { MdArrowBackIos } from "react-icons/md";
import { BsLightningCharge } from "react-icons/bs";
import logoo from "../.././assets/logo.png";
import { useLocation } from "react-router-dom"; // Import useLocation
import { getUserInfo } from "../../redux/actions/auth";
const ProfileSm = ({ selected, setSelected, formData, setFormData, handleBack, isPasswordMatch, handleInputChange, handleSubmit, handleLogout }) => {
  return (
    <>
      <style>
        {`
          .ant-select-selector {
            background-color: #fff !important;
            border-radius: 10px !important;
            
          }
          .ant-select-selection-item {
            color: gray !important;
          }
        `}
      </style>

      <div className="flex items-center justify-between px-2 mt-8 md:mt-12 md:px-20">
        <div className="flex items-center justify-center gap-2">
          <MdArrowBackIos className="font-semibold text-[24px] cursor-pointer" onClick={handleBack} />
          <img src={logoo} className="md:hidden" />
        </div>
        <div></div>
        {/* <div className="flex gap-1 cursor-pointer w-[68px] h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
          <BsLightningCharge className="font-semibold" />
          <span>425</span>
        </div> */}
      </div>
      <div className="flex flex-col justify-center mt-8 ">
        <div className="flex flex-col items-center justify-center cursor-pointer ">
          <img src={logo} alt="logo" />
          <span className="font-bold text-[16px] mt-2 ">{formData.fullName}</span>
          <div className="flex flex-row items-center justify-center gap-1 " onClick={handleLogout}>
            <span className={`cursor-pointer ${selected === "Logout" && "border-b-2 border-[#4E46DC]"}`}>Logout</span>
            <FiLogOut className="cursor-pointer " />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-8 mt-8 font-bold text-[18px] flex-wrap">
        <span onClick={() => setSelected("Credits")} className={`cursor-pointer ${selected === "Credits" && "border-b-2 border-[#4E46DC]"}`}>
          Credits
        </span>
        <span onClick={() => setSelected("Profile")} className={`cursor-pointer ${selected === "Profile" && "border-b-2 border-[#4E46DC]"}`}>
          Profile
        </span>
        <span onClick={() => setSelected("Subscription")} className={`cursor-pointer ${selected === "Subscription" && "border-b-2 border-[#4E46DC]"}`}>
          Subscription
        </span>
      </div>

      {selected == "Profile" && (
        <>
          <div className="border border-gray-100 "></div>

          <form className="flex flex-wrap items-center justify-between px-4 mt-8">
            {/* first Name */}
            <div className="flex flex-col w-full gap-1">
              <label htmlFor="full-name" className="font-bold">
                Full Name
              </label>
              <input
                type="text"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="Enter your full name"
                name="fullName"
                className="border border-solid border-gray-200 rounded-[10px] outline-none p-4 w-full h-[42px]"
              />
            </div>

            {/* email */}
            <div className="flex flex-col w-full gap-1 mt-6">
              <label htmlFor="first-name" className="font-bold">
                Email Address
              </label>
              <input type="text" placeholder="example@example.com" className="border border-solid border-gray-200 rounded-[10px] outline-none p-4 w-full h-[42px]" value={formData.email} />
            </div>

            {/* Password */}
            <div className="flex flex-col w-full gap-1 mt-6">
              <label htmlFor="last-name" className="font-bold">
                Password
              </label>
              <input
                value={formData.password}
                onChange={handleInputChange}
                name="password"
                type="password"
                placeholder="At least 8 characters"
                className="border border-solid border-gray-200 rounded-[10px] outline-none p-4 w-full h-[42px]"
              />
            </div>

            {/* New Password */}
            <div className="flex flex-col w-full gap-3 mt-2">
              <label className="font-semibold">New Password</label>
              <input
                type="password"
                placeholder="New password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
                className={`p-4 border border-gray-200 rounded-lg w-full outline-none h-[50px] ${isPasswordMatch ? "" : "border-red-500"}`}
              />
            </div>

            {/* Confirm New Password */}
            <div className="flex flex-col w-full gap-3 mt-2">
              <label className="font-semibold">Confirm New Password</label>
              <input
                type="password"
                placeholder="Confirm password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className={`p-4 border border-gray-300 rounded-lg w-full outline-none h-[50px] ${
                  formData.newPassword == "" || formData.newPassword == null ? "border border-gray-300" : isPasswordMatch ? "border-green-500" : "border-red-500"
                }`}
              />
              {!isPasswordMatch && <p className="text-red-500">Passwords do not match</p>}
            </div>

            {/* Contact number */}
            <div className="flex flex-col w-full gap-1 mt-6">
              <label htmlFor="last-name" className="font-bold">
                Your Number
              </label>
              <input
                type="text"
                name="number"
                value={formData.number}
                onChange={handleInputChange}
                placeholder="Contact Number"
                className="border border-solid border-gray-200 rounded-[10px] outline-none p-4 w-full h-[42px]"
              />
            </div>
          </form>

          {/* buttons for save or cancle */}
          <div className="flex items-center justify-center gap-10 px-4 mb-8 py-11">
            <button className="w-[145px] py-2 md:py-0 text-[#4E46DC] rounded-[15px] border-2 border-solid border-[#4E46DC] font-semibold">Cancel</button>
            <button className="w-[145px] py-3 md:py-0 rounded-[15px] font-bold text-white btn-primary " onClick={handleSubmit}>
              Save
            </button>
          </div>
        </>
      )}

      {selected == "Subscription" && (
        <>
          <div className="border border-gray-100 "></div>
          <Subscription />
        </>
      )}

      {selected == "Credits" && (
        <>
          <div className="border border-gray-100 "></div>
          <Credits />
        </>
      )}
    </>
  );
};

const ProfileLg = ({ selected, setSelected, formData, setFormData, isPasswordMatch, handleInputChange, handleSubmit, handleLogout }) => {
  return (
    <>
      <div className="flex flex-row justify-between mt-20 px-28">
        <div className="flex flex-row items-center justify-center gap-4 p-2 cursor-pointer ">
          <img src={logo} alt="logo" />
          <span className="font-bold text-[20px]">{formData.fullName}</span>
        </div>
        <div className="flex flex-row items-center gap-4 p-2" onClick={handleLogout}>
          <span className={`cursor-pointer ${selected === "Logout" && "border-b-2 border-[#4E46DC]"}`}>Logout</span>
          <FiLogOut className="cursor-pointer " />
        </div>
      </div>

      <div className="flex flex-row items-center gap-36 mt-8 px-28 font-bold text-[18px]">
        <span onClick={() => setSelected("Credits")} className={`cursor-pointer ${selected === "Credits" && "  border-b-2 border-[#4E46DC]"}`}>
          {" "}
          Credits
        </span>
        <div onClick={() => setSelected("Profile")} className={`cursor-pointer  ${selected === "Profile" && "  border-b-2 border-[#4E46DC]"}`}>
          {" "}
          Profile
        </div>
        <span onClick={() => setSelected("Subscription")} className={`cursor-pointer ${selected === "Subscription" && "  border-b-2 border-[#4E46DC]"}`}>
          {" "}
          Subscription
        </span>
      </div>

      {selected == "Profile" && (
        <>
          <div className="border border-gray-100 "></div>
          {/* form for information */}
          <form className="grid justify-between grid-cols-2 px-4 mt-10 md:px-8 lg:px-28">
            {/* first Name */}
            <div>
              <div className="flex flex-col gap-3">
                <label htmlFor="first-name" className="font-semibold">
                  Full Name
                </label>
                <input
                  type="text"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  placeholder="enter your full name"
                  id="first-name"
                  name="fullName"
                  className="p-6 border border-gray-200 rounded-lg w-3/4 outline-none h-[50px]"
                />
              </div>

              {/* email */}
              <div className="flex flex-col gap-3 mt-2">
                <label className="font-semibold">Email Adress</label>
                <input value={formData.email} type="email" readOnly placeholder="example@example.com" className="p-6 border border-gray-200 rounded-lg w-3/4 outline-none h-[50px]" />
              </div>
              <div className="flex flex-col gap-3 mt-2">
                <label className="font-semibold">Your Number</label>
                <input
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleInputChange}
                  placeholder="enter your number"
                  className="p-6 border border-gray-300 rounded-lg w-3/4 outline-none h-[50px] "
                />
              </div>

              {/* Password */}
            </div>
            <div className="">
              <div className="flex flex-col gap-3 ">
                <label htmlFor="last-name" className="font-semibold">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter your password"
                  className="p-6 border border-gray-300 rounded-lg w-3/4 outline-none h-[50px]"
                />
              </div>
              <div className="flex flex-col gap-3 mt-2">
                <label className="font-semibold">New Password</label>
                <input
                  type="password"
                  placeholder="new password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  className={`p-6 border border-gray-200 rounded-lg w-3/4 outline-none h-[50px] ${isPasswordMatch ? "" : "border-red-500"}`}
                />
              </div>

              {/* Confirm password */}
              <div className="flex flex-col gap-3 mt-2">
                <label className="font-semibold">Confirm New Password</label>
                <input
                  type="password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className={`p-6 border border-gray-300 rounded-lg w-3/4 outline-none h-[50px]  ${
                    formData.newPassword == "" || formData.newPassword == null ? "border border-gray-300" : isPasswordMatch ? "border-green-500" : "border-red-500"
                  }`}
                />
                {!isPasswordMatch && <p className="text-red-500">Passwords do not match</p>}
              </div>
            </div>
            {/* Contact number */}
          </form>
          {/* buttons for save or cancle */}
          <div className="flex justify-end gap-10 py-4 my-5 px-28">
            <button className="w-[203.88px] py-2 rounded-xl border-2 border-solid border-[#4E46DC] font-semibold">Cancel</button>
            <button onClick={handleSubmit} className="w-[203.88px] py-3 rounded-xl font-bold text-white border border-solid border-[#4E46DC] bg-[#4E46DC]">
              Save
            </button>
          </div>
        </>
      )}

      {selected == "Subscription" && (
        <>
          <div className="border border-gray-100 "></div>
          <Subscription />
        </>
      )}

      {selected == "Credits" && (
        <>
          <div className="border border-gray-100 "></div>
          <Credits />
        </>
      )}
    </>
  );
};

const Profile = () => {
  const location = useLocation(); // Hook to get location object

  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");
  useEffect(() => {
    if (tab) setSelected(tab);
  }, [tab]);
  const [selected, setSelected] = useState(tab ? tab : "Profile");
  const [userInfo, setUserInfo] = useState({
    name: "",
    password: "",
    email: "",
    number: "",
  });

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    number: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log({ name, value });
    setFormData({ ...formData, [name]: value });
  };
  const isPasswordMatch = formData.newPassword === formData.confirmPassword;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user/get-user-info");
        console.log(response);
        const fetchedUserInfo = response.data.user;
        console.log(fetchedUserInfo);
        setFormData({
          id: fetchedUserInfo.id,
          fullName: fetchedUserInfo.name,
          email: fetchedUserInfo.email,
          number: fetchedUserInfo.number,
          name: "",
          password: "",
          newPassword: "",
          confirmPassword: "",
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      if (!isPasswordMatch) return message.error("Passwords do not match!");
      if (formData.newPassword && !formData.password) {
        return message.error("Please enter your current password!");
      }
      if (formData.newPassword.length < 8 && formData.newPassword != "") {
        return message.error("Password must be at least 8 characters long!");
      }
      const response = await axios.post(`/user/update-user-info`, {
        email: formData.email, // Assuming email is not editable in this form
        password: formData.password,
        newPassword: formData.newPassword,
        name: formData.fullName,
        number: formData.number,
        role: "user", // Assuming the role is not editable in this form
        id: formData.id,
      });

      // Handle the response, show success message, etc.
      console.log(response.data);
      message.success("Records Updated!");
    } catch (error) {
      // Handle error, show error message, etc.
      console.error("Error updating user information:", error);
      message.error("Error!");
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    await dispatch(logOut());
    navigate("/signin");
    window.location.reload();
  };
  const handleBack = () => {
    navigate("/home");
  };
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  return (
    <>
      <div className="sm:hidden">
        <ProfileSm
          selected={selected}
          setSelected={setSelected}
          formData={formData}
          setFormData={setFormData}
          isPasswordMatch={isPasswordMatch}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          handleBack={handleBack}
          handleLogout={handleLogout}
        />
      </div>
      <div className="hidden sm:block">
        <ProfileLg
          selected={selected}
          setSelected={setSelected}
          formData={formData}
          setFormData={setFormData}
          isPasswordMatch={isPasswordMatch}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          handleLogout={handleLogout}
        />
      </div>
    </>
  );
};

export default Profile;
