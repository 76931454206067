import React, { useState } from "react";
import GridLoader from "react-spinners/GridLoader";

const MainLoader = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <GridLoader
        loading={loading}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
        color="#4E46DC"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "red",
        }}
      />
    </div>
  );
};

export default MainLoader;
