import React, { useEffect, useState } from "react";
import { BiCopy } from "react-icons/bi";
import { BiExport } from "react-icons/bi";
import { RiDeleteBinLine, RiAddCircleFill } from "react-icons/ri";
import QuillEditor from "../../components/QuillEditor";
import { Dropdown, message, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { BsLightningCharge } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logo.png";
import { changeName, createTransform, saveTrans } from "../../redux/actions/library";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { ErrorBoundary } from "react-error-boundary";
import MainLoader from "../../components/MainLoader";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
const TemplateEdit = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useCases, selectedUseCase, user } = useSelector((state) => state.auth);
  const { selectedlibrary, libraries } = useSelector((state) => state.library);
  const [fieldsVal, setFieldsVal] = useState({});
  const [responses, setResponses] = useState("");
  const [tempTransId, setTempTransId] = useState(null);
  const [resComp, setResComp] = useState(true);
  const [displayText, setDisplayText] = useState();
  const [displayCheck, setDisplayCheck] = useState(false);
  // console.log(selectedlibrary);
  const [selectedTransformation, setSelectedTransformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Add/remove a class or style to the body or container based on dropdown state
  useEffect(() => {
    const body = document.body;
    if (dropdownOpen) {
      body.style.overflow = "hidden"; // Disable scrolling on the body
    } else {
      body.style.overflow = "auto"; // Enable scrolling
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (selectedlibrary && selectedlibrary.id) {
      setDisplayText(selectedlibrary?.text && selectedlibrary?.text.length > 60 ? selectedlibrary?.text.substring(0, 60) + "..." : selectedlibrary?.text);
    }
  }, [selectedlibrary]);
  useEffect(() => {
    console.log(fieldsVal);
  }, [fieldsVal]);

  const [nameChange, setNameChange] = useState("untitled");

  const handleEdit = async (e, id) => {
    setNameChange(e.target.value);
    console.log(e.target.value);
    console.log(id);

    dispatch(changeName(e.target.value, id, selectedlibrary?.description));
  };

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleGenerateContent = async () => {
    setButtonDisabled(true);
    setResComp(false);
    message.loading("Loading...");
    try {
      const res = await dispatch(createTransform(selectedUseCase.id, selectedlibrary.id, fieldsVal));
      if (!res) {
        message.error("Something went wrong!");
        return;
      }
      setTempTransId(res.id);
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.error("No token found");
        return;
      }
      const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/transformation/stream?id=${res.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // console.log(eventSource);
      setResponses("");
      eventSource.onmessage = function (event) {
        const data = JSON.parse(event.data);
        // console.log("Stream Data:", data.message);
        setResponses((resp) => resp + data.message);

        let contentdiv = document.getElementById("content-div");
        contentdiv.scrollTop = contentdiv.scrollHeight;
        if (data.message === "end-of-stream") {
          // Handle end of stream
          console.log("Stream ended. Updated record:", data.updatedRecord);
          setSelectedTransformation(data.updatedRecord);
          setResComp(true);
          setResponses("");
          eventSource.close();
          setButtonDisabled(false);
        }
      };
      eventSource.onerror = function (error) {
        console.log(error);
        console.error("EventSource failed:", error);
        eventSource.close();
        setResComp(true);
        setButtonDisabled(false);
        message.error("Something went wrong! Check your usage.");
      };
      // message.success("Complete");
    } catch (error) {
      console.log(error, "Error in Functions");
      // toast.error("Something went wrong!")
      return false;
    }

    // navigate("/notes");
  };
  const handleDisp = async (e) => {
    const c = e.target.checked;
    setDisplayCheck(c);
    // if c true then display full else only display 20 chars and ...
    const text = c ? selectedlibrary?.text : selectedlibrary?.text && selectedlibrary?.text.length > 60 ? selectedlibrary?.text.substring(0, 60) + "..." : selectedlibrary?.text;
    console.log(text);
    setDisplayText(text);
  };
  const items = [
    {
      key: "1",
      label: (
        <div className="flex flex-row items-center justify-start gap-2 w-[90px]  ">
          <BiCopy />
          <p className="font-semibold">Copy</p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex flex-row items-center justify-start gap-2 w-[90px]  ">
          <BiExport />
          <p className="font-semibold">Export</p>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex flex-row items-center justify-start gap-2 w-[90px]  ">
          <RiDeleteBinLine />
          <p className="font-semibold">Delete</p>
        </div>
      ),
    },
  ];
  const handleBackClick = () => {
    navigate("/templates");
  };
  const saveTransHandler = async () => {
    const res = await dispatch(saveTrans(tempTransId));
    if (!res) {
      message.error("Something went wrong!");
      return;
    }
    message.success("Saved Successfully!");
  };

  return (
    <>
      <style>
        {`
          .ant-select-selector {
            background-color: #fff !important;
            border-radius: 10px !important;
          
          }
          .ant-select-selection-item {
            color: gray !important;
            
          }
         
        `}
      </style>

      {loading ? (
        <MainLoader />
      ) : (
        <>
          <div className="flex items-center justify-between px-2 mt-8 md:mt-12 md:px-20">
            <div className="flex items-center justify-center gap-2">
              <MdArrowBackIos className="font-semibold text-[24px] cursor-pointer" onClick={handleBackClick} />
              <img src={logo} className="md:hidden" />
            </div>
            {user && !user?.infiniteCeiling ? (
              <div className="flex gap-1 cursor-pointer w-[68px] h-[32px] bg-[#4E46DC] font-semibold items-center justify-center text-white rounded-[10px]">
                <BsLightningCharge className="font-semibold" />
                <span>{Math.max(0, user.monthlyCreditCeiling - user.currentMonthUsage)}</span>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className="flex flex-col px-5 mt-10 md:px-20">
            <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 ">
              {/* Content for the first column */}
              <div className="w-full overflow-hidden md:overflow-y-auto md:max-h-[80vh]">
                {selectedlibrary && (
                  <div className={`bg-white h-auto rounded-xl border border-[#EAEAEA]  w-full flex flex-col  items-center ${!false && " justify-between gap-0"}`}>
                    <div className="flex items-center justify-start w-full ">
                      <svg width="41" height="41" className="m-3" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="41" height="41" rx="5" fill="#F2F5F7" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.9024 13.2723C17.7243 12.4577 18.8389 12 20.0012 12C21.1634 12 22.2781 12.4577 23.0999 13.2723C23.9218 14.087 24.3835 15.1918 24.3835 16.3439V21.0038C24.3835 22.1558 23.9218 23.2607 23.0999 24.0754C22.2781 24.89 21.1634 25.3477 20.0012 25.3477C18.8389 25.3477 17.7243 24.89 16.9024 24.0754C16.0806 23.2607 15.6189 22.1558 15.6189 21.0038V16.3439C15.6189 15.1918 16.0806 14.087 16.9024 13.2723ZM20.0012 13.6981C19.2933 13.6981 18.6143 13.9769 18.1138 14.4731C17.6132 14.9692 17.332 15.6422 17.332 16.3439V21.0038C17.332 21.7055 17.6132 22.3784 18.1138 22.8746C18.6143 23.3708 19.2933 23.6496 20.0012 23.6496C20.7091 23.6496 21.388 23.3708 21.8886 22.8746C22.3892 22.3784 22.6704 21.7055 22.6704 21.0038V16.3439C22.6704 15.6422 22.3892 14.9692 21.8886 14.4731C21.388 13.9769 20.7091 13.6981 20.0012 13.6981ZM11.7333 21.3285C12.2014 21.261 12.6362 21.5824 12.7043 22.0464C12.9594 23.784 13.8371 25.3724 15.1768 26.521C16.5165 27.6697 18.2286 28.3019 20 28.3019C21.7714 28.3019 23.4835 27.6697 24.8232 26.521C26.1629 25.3724 27.0406 23.784 27.2957 22.0464C27.3638 21.5824 27.7986 21.261 28.2667 21.3285C28.7348 21.396 29.0591 21.827 28.991 22.291C28.6766 24.4323 27.5949 26.3898 25.9439 27.8053C24.2929 29.2209 22.183 30 20 30C17.817 30 15.7071 29.2209 14.0561 27.8053C12.4051 26.3898 11.3234 24.4323 11.009 22.291C10.9409 21.827 11.2652 21.396 11.7333 21.3285Z"
                          fill="#A9BED0"
                        />
                      </svg>
                      <div>
                        <p contentEditable="true" value={nameChange} onChange={(e) => handleEdit(e, selectedlibrary.id)} className="text-base font-bold ">
                          {selectedlibrary && selectedlibrary.name}
                        </p>
                        <p className="text-[#C4C4C4] text-xs">{selectedlibrary && selectedlibrary.createdAt ? new Date(selectedlibrary.createdAt).toDateString() : "No date available"}</p>
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-start px-4">
                      <input type="checkbox" name="" id="eye" onChange={handleDisp} className="hidden" />
                      <p dangerouslySetInnerHTML={{ __html: displayText }} className="w-full text-[#C4C4C4]"></p>
                      {displayCheck ? (
                        <EyeInvisibleOutlined
                          color="#ADADAD"
                          className="text-[#ADADAD] cursor-pointer py-2"
                          onClick={() => {
                            document.getElementById("eye").click();
                          }}
                        />
                      ) : (
                        <EyeOutlined
                          color="#ADADAD"
                          className="text-[#ADADAD] cursor-pointer py-2"
                          onClick={() => {
                            document.getElementById("eye").click();
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="relative w-full mt-6">
                  <Select
                    style={{
                      width: "100%",
                      height: 50,
                    }}
                    placeholder="Select a note"
                    defaultValue={selectedlibrary ? selectedlibrary.id : undefined}
                    onDropdownVisibleChange={(open) => setDropdownOpen(open)}
                    options={[
                      {
                        value: "add-new", // Unique value for the "Add New" option
                        label: (
                          <div
                            className="flex items-center justify-center"
                            onClick={() => {
                              // Define your logic for "Add New" click here
                              navigate("/home");
                            }}>
                            <span className="mr-2">
                              <RiAddCircleFill />
                            </span>
                            <span>Add New</span> {/* Text for the "Add New" option */}
                          </div>
                        ),
                      },
                      ...(libraries &&
                        libraries.map((data) => ({
                          value: data.id, // Assuming id is unique
                          label: (
                            <div
                              className="flex items-center"
                              onClick={() => {
                                dispatch({ type: "SET_SELECTED_LIBRARY", payload: data });
                              }}>
                              <span className="mr-2">{data.icon}</span>
                              <span>{data.name}</span>
                            </div>
                          ),
                        }))),
                    ]}
                  />
                </div>
                <div className="relative w-full mt-6">
                  <Select
                    style={{
                      width: "100%",
                      height: 50,
                    }}
                    placeholder="Select a usecase"
                    defaultValue={selectedUseCase ? selectedUseCase.id : undefined}
                    onDropdownVisibleChange={(open) => setDropdownOpen(open)}
                    options={
                      useCases &&
                      user && // Add a check for user object
                      user.planType !== null &&
                      useCases
                        .filter((data) => data.active)
                        // .filter((data) => (user.planType === "basic" ? data.membershipType === "basic" : true))
                        .map((data) => ({
                          value: data.id, // Assuming id is unique
                          label: (
                            <div
                              className="flex items-center"
                              onClick={() => {
                                setFieldsVal({});
                                dispatch({ type: "SET_SELECTED_USECASES", payload: data });
                              }}>
                              <span className="mr-2">{data.icon}</span>
                              <span>{data.name}</span>
                            </div>
                          ),
                        }))
                    }
                  />
                </div>

                {selectedUseCase &&
                  selectedUseCase.fields &&
                  selectedUseCase.fields.map((val, index) => {
                    const varName = val.varName;
                    return (
                      <div className="relative flex flex-col w-full mt-3" key={index + " " + selectedUseCase.id + " " + val.label}>
                        <div className="flex flex-row justify-between w-full p-2">
                          <label className="font-bold">{val.label}</label>
                          <span className="text-gray-400"></span>
                        </div>
                        {val.input == "text" && (
                          <input
                            type="text"
                            required={Boolean(val.required)}
                            value={fieldsVal[varName]}
                            onChange={(e) =>
                              setFieldsVal({
                                ...fieldsVal,
                                [varName]: e.target.value,
                              })
                            }
                            className="w-full p-3 text-gray-500 border border-gray-200 rounded-xl focus:outline-none "
                            placeholder={val.placeholder}
                          />
                        )}
                        {val.input == "textarea" && (
                          <textarea
                            value={fieldsVal[varName]}
                            onChange={(e) =>
                              setFieldsVal({
                                ...fieldsVal,
                                [varName]: e.target.value,
                              })
                            }
                            className="w-full h-20 p-3 text-gray-500 border border-gray-200 rounded-xl focus:outline-none"
                            required={Boolean(val.required)}
                            placeholder={val.placeholder}></textarea>
                        )}
                      </div>
                    );
                  })}

                {/* Button at bottom-right */}

                <div className="flex items-end justify-center md:justify-end mt-4">
                  {selectedUseCase && selectedlibrary ? (
                    <button
                      disabled={buttonDisabled}
                      className="mb-20 md:mb-2 md:mt-4 ml-2  h-fit py-[0.55rem] btn-primary rounded-[15px] text-[16px] px-7 "
                      type="submit"
                      onClick={handleGenerateContent}>
                      {buttonDisabled ? "Loading..." : "Generate content"}
                    </button>
                  ) : (
                    <button disabled={true} className="mb-20 md:mb-2 md:mt-4 ml-2  h-fit py-[0.55rem] btn-primary rounded-[15px] text-[16px]  mt-4  btn-primary opacity-70  px-7" type="submit">
                      {buttonDisabled ? "Loading..." : "Generate content"}
                    </button>
                  )}
                </div>
              </div>

              {/* Content for the second column */}
              <div className="w-full md:overflow-y-auto md:max-h-[80vh]" id="content-div">
                {resComp ? (
                  <div className="">
                    <div className={`bg-white h-auto rounded-xl border  border-[#EAEAEA]  w-full  flex flex-col  items-center mb-16 md:mb-0 ${!false && " justify-between gap-0"}`}>
                      <div className="flex justify-between w-full border-b  h-8">
                        <div></div>
                        {/* <Dropdown
                          menu={{
                            items,
                          }}
                          className=""
                          placement="bottom">
                          <svg width="4" height="14" className="z-40 mx-3 my-3 cursor-pointer" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="1.75" cy="1.75" r="1.75" fill="#B0AEAE" />
                            <circle cx="1.75" cy="7" r="1.75" fill="#B0AEAE" />
                            <circle cx="1.75" cy="12.25" r="1.75" fill="#B0AEAE" />
                          </svg>
                        </Dropdown> */}
                      </div>
                      {/* <QuillEditor text={selectedlibrary?.text || ""} selectedlibrary={selectedlibrary} /> */}
                      <ErrorBoundary fallback={selectedTransformation?.output ? <div dangerouslySetInnerHTML={selectedTransformation?.output} /> : <div>Something's Wrong. Retry please.</div>}>
                        <QuillEditor text={selectedTransformation?.output || ""} selectedTransformation={selectedTransformation} />
                      </ErrorBoundary>
                    </div>
                    <div className="w-full flex text-right">
                      <button
                        onClick={saveTransHandler}
                        className=" mb-20 md:mb-2 md:mt-4 ml-auto border-2 text-primary font-bold border-primary h-fit rounded-xl text-xs md:text-sm px-5 md:px-7 py-[0.50rem] hover:shadow-primary hover:shadow-xs hover:bg-primary hover:text-white">
                        Save
                      </button>

                      <button className="mb-20 md:mb-2 md:mt-4 ml-2  h-fit py-[0.55rem] btn-primary rounded-[15px] text-[16px] md:w-[234px]" onClick={() => navigate("/transformation")} type="submit">
                        View all Outputs
                      </button>
                    </div>
                  </div>
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: responses }} className="px-2 p-4 mb-20 md:mb-0"></p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TemplateEdit;
