import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "../../redux/actions/AxiosInstance/authenticated";
import unauthAxio from "axios";

const LiveAudioVisualizer = ({
  setTime,
  onPlay,
  onPause,
  isRecording,
  setIsRecording,
  socketRef,
  isPlaying,
  affirmation,
  setAffirmation,
  language,
  finalAffirmation,
  setFinalAffirmation,
  loading,
  setStartRecord,
  setLoading,
}) => {
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [bars, setBars] = useState([]);
  const [dataAccumulator, setDataAccumulator] = useState([]);
  const [lastDrawTime, setLastDrawTime] = useState(Date.now());
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [recordingData, setRecordingData] = useState([]);
  const [recordingLength, setRecordingLength] = useState(0);
  const [audioSource, setAudioSource] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(600);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const [supportedMimeTypes, setSupportedMimeTypes] = useState([]);

  // New state variables for the feature
  const [transcriptText, setTranscriptText] = useState("");
  const transcriptTextRef = useRef("");
  const [libId, setLibId] = useState(null);
  const libIdRef = useRef(null);

  const intervalId = useRef(null);

  useEffect(() => {
    const checkSupportedAudioMimeTypes = () => {
      const typesToCheck = [
        "audio/webm",
        "audio/ogg",
        "audio/wav",
        "audio/mpeg",
        "audio/aac",
        "audio/mp4",
        "audio/x-aac",
        "audio/ogg; codecs=opus",
        "audio/webm; codecs=opus",
        "audio/flac",
        "audio/x-hx-aac-adts",
        "audio/aacp",
        "audio/mp3",
        "audio/x-mp3",
        "audio/x-mpeg",
        "audio/x-mpeg3",
        "audio/x-mpegaudio",
        "audio/ogg; codecs=speex",
        "audio/speex",
        "audio/amr",
        "audio/3gpp",
        "audio/3gpp2",
        "audio/ac3",
        "audio/vnd.dolby.dd-raw",
        "audio/x-ms-wma",
        "audio/vnd.rn-realaudio",
        "audio/x-pn-realaudio",
        "audio/x-pn-realaudio-plugin",
        "audio/aiff",
        "audio/x-aiff",
        "audio/alac",
        "audio/x-alac",
        "audio/midi",
        "audio/x-midi",
        "audio/dsd",
      ];

      const supportedTypes = typesToCheck.filter((type) => MediaRecorder.isTypeSupported(type));
      setSupportedMimeTypes(supportedTypes);
    };

    checkSupportedAudioMimeTypes();
  }, []);

  useEffect(() => {
    const updateCanvasWidth = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setCanvasWidth((width * 2) / 3);
      } else {
        setCanvasWidth(600);
      }
    };
    updateCanvasWidth();
    window.addEventListener("resize", updateCanvasWidth);
    return () => {
      window.removeEventListener("resize", updateCanvasWidth);
    };
  }, []);

  useEffect(() => {
    if (isRecording) {
      startRecording();
    }
  }, [isRecording]);

  useEffect(() => {
    if (!isRecording) {
      stopRecording();
    }
  }, [isRecording]);

  useEffect(() => {
    if (isPlaying) {
      playPlaybackRecording();
    } else {
      pausePlaybackRecording();
    }
  }, [isPlaying]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvasWidth;
    canvas.height = !isRecording ? 0 : 90;
    const ctx = canvas.getContext("2d");
    const barWidth = 2;
    const barGap = 1.5;
    const maxBars = Math.floor(canvas.width / (barWidth + barGap));
    const draw = () => {
      if (analyser && isRecording) {
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyser.getByteFrequencyData(dataArray);
        const maxDataValue = Math.max(...dataArray);
        setDataAccumulator((prev) => [...prev, maxDataValue]);
        const now = Date.now();
        if (now - lastDrawTime >= 250) {
          const scaleFactor = 0.95;
          let barHeight = Math.min((Math.max(...dataAccumulator) / 255) * canvas.height * scaleFactor, canvas.height);
          barHeight = barHeight == 0 || barHeight == -Infinity ? 5 : barHeight;
          const newBars = [barHeight, ...bars];
          if (newBars.length > maxBars) newBars.pop();
          setBars(newBars);
          setDataAccumulator([]);
          setLastDrawTime(now);
        }
      }

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      bars.forEach((bar, index) => {
        ctx.fillStyle = "#4E46DC";
        const y = (canvas.height - bar) / 2;
        ctx.fillRect(index * (barWidth + barGap), y, barWidth, bar);
      });

      requestAnimationFrame(draw);
    };

    draw();
  }, [analyser, bars, isRecording, canvasWidth]);

  const handleUserStart = async () => {
    try {
      await resumeAudioContext();
      await startRecording();
    } catch (error) {
      // Handle error
    }
  };

  const resumeAudioContext = async () => {
    if (audioContext && audioContext.state === "suspended") {
      await audioContext.resume();
    }
  };

  const fetchUploadUrl = async (file) => {
    const fileName = file.name;
    const { data } = await axios.post("/assets/create", {
      fileName,
      folder: "audio",
      fileType: file.type,
      type: "upload",
    });
    return data;
  };

  const startRecording = async () => {
    const startTime = new Date();
    let audioBlobArr = [];
    setTranscriptText("");
    transcriptTextRef.current = "";
    setAffirmation("");
    setLibId(null);
    libIdRef.current = null;

    try {
      setLoading(true);

      // Create the lib at the beginning
      const createLibResponse = await axios.post("/library/create-transcript", {
        name: "Live Transcript",
      });

      if (createLibResponse.data.status) {
        setLibId(createLibResponse.data.libId);
        libIdRef.current = createLibResponse.data.libId;
      } else {
        message.error("Failed to create transcript");
        setLoading(false);
        setIsRecording(false);
        return;
      }

      // Fetch API key for Deepgram
      const { data } = await axios.post("/deepgram/key");
      if (!data.key && !data.status) {
        message.error(data.message);
        setLoading(false);
        setIsRecording(false);
        navigate("/home");
        return;
      }
      const { key, api_key_id } = data;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: isIOS ? "audio/mp4" : "audio/webm",
      });

      const socket = new WebSocket(`wss://api.deepgram.com/v1/listen?model=nova-2&smart_format=true&punctuate=true&diarize=true&paragraphs=true&language=${language}`, ["token", key]);

      socketRef.current = socket;

      socket.onopen = () => {
        mediaRecorder.start(1000);
        mediaRecorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
            audioBlobArr.push(event.data);
            setLoading(false);
            setStartRecord(true);
            socket.send(event.data);
          }
        });
      };

      socket.onmessage = async (message) => {
        const received = JSON.parse(message.data);
        const transcript = received.channel.alternatives[0].transcript;
        if (transcript) {
          setTranscriptText((prev) => {
            const newTranscript = prev + " " + transcript;
            transcriptTextRef.current = newTranscript;
            return newTranscript;
          });
          setAffirmation((prev) => prev + " " + transcript);
        }
      };

      // Update transcript every 10 seconds
      intervalId.current = setInterval(() => {
        const textToSend = transcriptTextRef.current.trim();
        if (libIdRef.current && textToSend) {
          axios.post("/library/update-transcript", {
            libId: libIdRef.current,
            text: textToSend,
          });
          transcriptTextRef.current = "";
          setTranscriptText("");
        }
      }, 10000);

      socket.onclose = async () => {
        const endTime = new Date();
        const time = (endTime.getTime() - startTime.getTime()) / 1000;

        // Clear interval
        if (intervalId.current) {
          clearInterval(intervalId.current);
          intervalId.current = null;
        }

        // Send any remaining transcript
        if (transcriptTextRef.current.trim() && libIdRef.current) {
          await axios.post("/library/update-transcript", {
            libId: libIdRef.current,
            text: transcriptTextRef.current.trim(),
          });
          transcriptTextRef.current = "";
          setTranscriptText("");
        }

        setLoading(true);
        try {
          const audioBlob = new Blob(audioBlobArr, {
            type: "audio/webm",
          });

          await axios.post("/deepgram/revoke-key", { api_key_id });

          const resp = await fetchUploadUrl({
            name: "audio.webm",
            type: audioBlob.type,
          });
          const result = await unauthAxio.put(resp.presignedUrl, audioBlob, {
            headers: {
              "Content-Type": audioBlob.type,
            },
          });
          if (result.status === 200) {
            await axios.post("/assets/create", {
              assetId: resp.asset.id,
              type: "view",
            });
          }

          // Finalize lib with assetId
          await axios.post("/library/finalize-transcript", {
            libId: libIdRef.current,
            time,
            assetId: resp.asset.id,
          });

          message.success("Transcription Created!");
        } catch (error) {
          message.error("Error Occurred!");
        }
        setLoading(false);
        navigate(`/home/transcript/${libIdRef.current}`);
      };

      socket.onerror = (error) => {
        axios.post("/user/save-error-log", { error });
      };

      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const analyserNode = audioCtx.createAnalyser();
      analyserNode.fftSize = 256;
      const source = audioCtx.createMediaStreamSource(stream);
      source.connect(analyserNode);

      const scriptProcessor = audioCtx.createScriptProcessor(4096, 1, 1);
      scriptProcessor.connect(audioCtx.destination);
      source.connect(scriptProcessor);
      scriptProcessor.onaudioprocess = (e) => {
        if (!isRecording) return;
        const leftChannel = e.inputBuffer.getChannelData(0);
        recordingData.push(new Float32Array(leftChannel));
        setRecordingLength((prevLength) => prevLength + 4096);
      };

      setAudioContext(audioCtx);
      setAnalyser(analyserNode);
      setIsRecording(true);
    } catch (error) {
      alert(error);
      await axios.post("/user/save-error-log", {
        error: JSON.stringify(error),
      });
      setLoading(false);
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    // Clear interval
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }

    // Send any remaining transcript
    if (transcriptTextRef.current.trim() && libIdRef.current) {
      axios.post("/library/update-transcript", {
        libId: libIdRef.current,
        text: transcriptTextRef.current.trim(),
      });
      transcriptTextRef.current = "";
      setTranscriptText("");
    }

    if (audioContext) {
      audioContext.close();
      setAudioContext(null);
      setAnalyser(null);
      setIsRecording(false);

      const concatenatedData = recordingData.reduce((accumulator, chunk) => accumulator.concat(Array.from(chunk)), []);

      const buffer = audioContext.createBuffer(1, concatenatedData.length, audioContext.sampleRate);
      const channelBuffer = buffer.getChannelData(0);

      channelBuffer.set(concatenatedData);

      setAudioBuffer(buffer);
      setRecordingData([]);
      setRecordingLength(0);

      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.close();
      }
    }
  };

  const playPlaybackRecording = () => {
    if (audioBuffer && audioContext) {
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start();
      setAudioSource(source);
      if (onPlay) onPlay();
    }
  };

  const pausePlaybackRecording = () => {
    if (audioSource) {
      audioSource.stop();
      setAudioSource(null);
      if (onPause) onPause();
    }
  };

  return (
    <div className="w-full text-center">
      <canvas ref={canvasRef} />
      {/* Other UI elements if necessary */}
    </div>
  );
};

export default LiveAudioVisualizer;
