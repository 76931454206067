import "quill/dist/quill.snow.css";
const ShareOutputs = ({ text }) => {
  return (
    <div id="editor-container2">
      <div className="ql-container ql-snow " style={{ border: 0 }}>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: text,
          }}></div>
      </div>
    </div>
  );
};

export default ShareOutputs;
