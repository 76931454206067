import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../../redux/actions/AxiosInstance/unAuthenticated";

function ResetPassword() {
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the URL
    const url = window.location.href;

    // Create URLSearchParams object
    let params = new URLSearchParams(url.split("?")[1]);

    // Get token from URL
    let token = params.get("token");

    // Set token
    setToken(token);
  }, []); // Empty array means this effect runs once on component mount
  const resetPassword = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    const email = e.target.email.value;
    // Send a POST request to the server
    try {
      const { data, status } = await instance.post("/user/reset-password", {
        email: email,
        password: password,
        token: token,
      });
      if (status === 200) {
        alert("Password reset successful");
        navigate("/signin");
      }
    } catch (error) {
      console.log(error, "Error in get settings");
    }
  };
  return (
    <div className="grid place-content-center items-center h-screen w-full">
      <div className="px-3 w-full">
        <p className=" text-center text-md font-semibold text-2xl text-[#101828] mt-4">Forgot password?</p>
        <p className=" text-center text-sm text-[#475467] mt-2">No worries, we’ll send you reset instructions.</p>
        <form onSubmit={resetPassword}>
          <div className="mt-5">
            <label htmlFor="email" className="text-[#4E5674] font-[500] text-sm ">
              Your email
            </label>
            <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-1.5 px-3">
              <input className="border-none outline-none w-full" type="text" name="email" id="email" placeholder="Email Address" required />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="text-[#4E5674] font-[500] ">
              Password *
            </label>
            <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
              <input className="border-none outline-none w-full " type="password" name="password" id="password" placeholder="••••••••" required />
            </div>
          </div>
          <div className="flex justify-center items-center gap-x-4 mt-5">
            <button
              type="submit"
              className="text-[12px] text-sm w-full cursor-pointer font-semibold  px-5 rounded-lg py-2 text-white bg-[#2970FF] shadow-navButton hover:shadow-navButton border-[1px] border-[#2970FF] ">
              Reset password
            </button>
          </div>
        </form>
        <p
          onClick={() => {
            navigate("/signin");
          }}
          className=" cursor-pointer font-[500] text-sm text-[#475467] mt-5 flex items-center justify-center gap-x-3">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          Back to log in
        </p>
      </div>
    </div>
  );
}

export default ResetPassword;
