import React, { useEffect, useState } from "react";
import { FaRobot } from "react-icons/fa";
import { CgTranscript } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../../components/MainLoader";
import { useNavigate, useParams } from "react-router-dom";
import { BiCopy } from "react-icons/bi";
import { getSharedLibrary } from "../../redux/actions/library";
import ShareOutputs from "./ShareOutputs";
import ReactAudioPlayer from "react-audio-player";

const Share = () => {
  const { shareId } = useParams();
  const { sharedLibrary, loading } = useSelector((state) => state.library);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const tabs = [
    {
      name: "Content",
      icon: <FaRobot />,
    },
    {
      name: "Transcript",
      icon: <CgTranscript />,
    },
  ];
  useEffect(() => {
    if (shareId) {
      dispatch(getSharedLibrary(shareId));
    }
  }, [shareId]);
  // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return (
    <>
      {/* <h1>Testing</h1> */}
      {/* {JSON.stringify(sharedLibrary)} */}
      {loading ? (
        <MainLoader />
      ) : (
        sharedLibrary &&
        sharedLibrary.id && (
          <>
            <div className="h-screen px-4 md:px-5 w-full md:w-1/2 mx-auto">
              <div className="flex flex-row justify-between items-center px-1">
                <img src={"/images/logo.png"} alt="logo" className="w-16 md:w-20 my-5 md:my-10 text-center" />
                {/* SignUP button */}
                <button className="flex flex-row items-center gap-2 bg-[#4E46DC] text-white px-4 py-2 rounded-lg text-sm md:text-base" onClick={() => navigate("/")}>
                  Sign Up
                </button>
              </div>
              <div className="flex flex-row items-center justify-between w-full px-4 border-4 border-gray-300 bg-gray-100 py-6 rounded-lg">
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold text-xl">{sharedLibrary.name}</p>
                  <p className="font-medium text-sm text-left w-full text-gray-700">{sharedLibrary?.description}</p>
                  <p className="text-gray-600 text-xs text-left w-full">{sharedLibrary ? new Date(sharedLibrary.createdAt).toDateString() : "No date available"}</p>
                </div>
              </div>
              {sharedLibrary && sharedLibrary?.presignedUrl && (
                <div className={`bg-white h-auto rounded-full mt-6 border border-[#EAEAEA]  w-full  flex flex-col  items-center ${!false && " justify-between gap-0"}`}>
                  <div className="flex items-center justify-start w-full">
                    <div className="w-full p-3">
                      {/* play audio */}
                      {/* <ReactAudioPlayer src={sharedLibrary?.presignedUrl} autoPlay={false} preload="auto" controls muted className="w-full p-0" /> */}
                      <audio controls preload="auto" className="w-full p-0" muted={false}>
                        <source src={sharedLibrary?.presignedUrl} type="audio/mp3" />
                        <source src={sharedLibrary?.presignedUrl} type="audio/mp4" />
                        <source src={sharedLibrary?.presignedUrl} type="audio/ogg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col md:flex-row mt-6 space-x-6 px-2 justify-between items-center">
                {/* Two button Tabs  */}
                <div className="flex flex-row items-center gap-2">
                  {tabs.map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      className={` ${index === tab ? "bg-black shadow text-white" : "bg-white"} hover:bg-gray-700 hover:text-black hover:shadow-xl transition duration-300 ease-in-out
              } hover:bg-slate-100 flex items-center justify-center space-x-2 py-2 px-4 rounded-lg`}
                      onClick={() => setTab(index)}>
                      <span>{item.icon}</span>
                      <span className="text-xs md:text-base">{item.name}</span>
                    </button>
                  ))}
                </div>
                <div
                  onClick={() => {
                    // copy the share link to clipboard
                    const el = document.createElement("textarea");
                    el.value = `${window.location.origin}/share/${shareId}`;
                    document.body.appendChild(el);
                    el.select();
                    el.setSelectionRange(0, 999999999);
                    document.execCommand("copy");
                    document.body.removeChild(el);
                    const textBox = document.getElementById("textBox");
                    textBox.textContent = "Copied!";
                    setTimeout(() => {
                      textBox.textContent = "Copy Link";
                    }, 2000);
                  }}
                  className="flex gap-1 cursor-pointer w-fit px-4 h-[32px] my-2 bg-gray-100 border-gray-200 text-black font-semibold items-center justify-center rounded-[10px]">
                  {/* {copy icon} */}
                  <BiCopy className="cursor-pointer" />
                  <span className="text-xs md:text-base" id="textBox">
                    Copy Link
                  </span>
                </div>
              </div>
              <div className="mt-5 w-full bg-gray-400 h-[0.8px] mb-12" />
              {tab == 1 && sharedLibrary?.text && (
                <ShareOutputs text={sharedLibrary?.text} />
                // <div
                //   className="px-2"
                //   dangerouslySetInnerHTML={{
                //     __html: sharedLibrary?.text,
                //   }}
                // />
              )}
              {tab == 0 &&
                sharedLibrary &&
                sharedLibrary.transformations &&
                [...sharedLibrary.transformations] // Create a shallow copy to avoid mutating the original array
                  .sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0)) // Sort by orderNumber, assuming null/undefined as 0
                  .map((e, i) => {
                    return (
                      <div key={i} className="flex flex-row items-center justify-between w-full px-4 border border-gray-300 bg-white shadow hover:shadow-lg py-6 rounded-lg my-4">
                        <div className=" items-center gap-2 px-2">
                          <p className="font-semibold text-xl mb-1">{e?.title ? e.title : e?.useCase?.name}</p>
                          {e?.output && <ShareOutputs text={e?.output} />}
                        </div>
                      </div>
                    );
                  })}
              <div className="h-20"></div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default Share;
