import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
// #A9BDD0 #fefefe
export const Sidebar = (props) => {
  const { sidebarClosingStatus } = props;

  // get current route
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname.slice(1);

  const [user, setUser] = useState({});
  const [selectedLink, setSelectedLink] = useState("dashboard");

  useEffect(() => {
    setSelectedLink(currentRoute);
  }, [currentRoute]);

  const UpperData = [
    {
      title: "Home",
      redirectLink: "home",
      selectedSvgCode: (
        <svg className="w-full" width="16" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 19.8945V13.0474C7 12.4171 7.53726 11.9062 8.2 11.9062H11.8C12.4627 11.9062 13 12.4171 13 13.0474V19.8945M9.30457 2.1057L1.50457 7.38056C1.18802 7.59464 1 7.94119 1 8.31058V18.1827C1 19.1281 1.80589 19.8945 2.8 19.8945H17.2C18.1941 19.8945 19 19.1281 19 18.1827V8.31058C19 7.94119 18.812 7.59464 18.4954 7.38056L10.6954 2.1057C10.2791 1.82414 9.72092 1.82414 9.30457 2.1057Z"
            stroke="#4E46DC"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      ),
      unSelectedSvgCode: (
        <svg className="w-full" width="16" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 19.8945V13.0474C7 12.4171 7.53726 11.9062 8.2 11.9062H11.8C12.4627 11.9062 13 12.4171 13 13.0474V19.8945M9.30457 2.1057L1.50457 7.38056C1.18802 7.59464 1 7.94119 1 8.31058V18.1827C1 19.1281 1.80589 19.8945 2.8 19.8945H17.2C18.1941 19.8945 19 19.1281 19 18.1827V8.31058C19 7.94119 18.812 7.59464 18.4954 7.38056L10.6954 2.1057C10.2791 1.82414 9.72092 1.82414 9.30457 2.1057Z"
            stroke="#A9BED0"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
    {
      title: "Templates",
      redirectLink: "templates",
      selectedSvgCode: (
        <svg className="w-full" width="16" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17 1.89453C18.1046 1.89453 19 2.77769 19 3.86713L19 7.23446C19 8.32389 18.1046 9.20705 17 9.20705H14C12.8954 9.20705 12 8.32389 12 7.23445L12 3.86713C12 2.77769 12.8954 1.89453 14 1.89453L17 1.89453Z"
            stroke="#4E46DC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 1.89453C1.89543 1.89453 0.999999 2.77769 0.999999 3.86713L1.00001 7.23446C1.00001 8.32389 1.89544 9.20705 3.00001 9.20705H6.00001C7.10458 9.20705 8.00001 8.32389 8.00001 7.23445L8 3.86713C8 2.77769 7.10457 1.89453 6 1.89453L3 1.89453Z"
            stroke="#4E46DC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 12.582C18.1046 12.582 19 13.4652 19 14.5546V17.9219C19 19.0114 18.1046 19.8945 17 19.8945H14C12.8954 19.8945 12 19.0114 12 17.9219L12 14.5546C12 13.4652 12.8954 12.582 14 12.582H17Z"
            stroke="#4E46DC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.00001 12.582C1.89544 12.582 1.00001 13.4652 1.00001 14.5546L1.00001 17.9219C1.00001 19.0114 1.89544 19.8945 3.00001 19.8945H6.00001C7.10458 19.8945 8.00001 19.0114 8.00001 17.9219L8.00001 14.5546C8.00001 13.4652 7.10458 12.582 6.00001 12.582H3.00001Z"
            stroke="#4E46DC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      unSelectedSvgCode: (
        <svg className="w-full" width="16" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.0001 1.61523C21.2746 1.61523 22.3078 2.63427 22.3078 3.89131L22.3078 7.77669C22.3078 9.03373 21.2746 10.0528 20.0001 10.0528H16.5386C15.2641 10.0528 14.2309 9.03373 14.2309 7.77668L14.2309 3.89131C14.2309 2.63427 15.2641 1.61523 16.5386 1.61523L20.0001 1.61523Z"
            stroke="#A9BED0"
            strokeWidth="2.30769"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.84626 1.61523C2.57176 1.61523 1.53857 2.63427 1.53857 3.89131L1.53858 7.77669C1.53858 9.03373 2.57177 10.0528 3.84628 10.0528H7.30781C8.58232 10.0528 9.6155 9.03373 9.6155 7.77668L9.61549 3.89131C9.61549 2.63427 8.58231 1.61523 7.3078 1.61523L3.84626 1.61523Z"
            stroke="#A9BED0"
            strokeWidth="2.30769"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.0001 13.947C21.2746 13.947 22.3078 14.966 22.3078 16.2231V20.1084C22.3078 21.3654 21.2746 22.3845 20.0001 22.3845H16.5386C15.2641 22.3845 14.2309 21.3654 14.2309 20.1084L14.2309 16.2231C14.2309 14.966 15.2641 13.947 16.5386 13.947H20.0001Z"
            stroke="#A9BED0"
            strokeWidth="2.30769"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.84627 13.947C2.57177 13.947 1.53858 14.966 1.53858 16.2231L1.53859 20.1084C1.53859 21.3654 2.57178 22.3845 3.84628 22.3845H7.30782C8.58232 22.3845 9.61551 21.3654 9.61551 20.1084L9.6155 16.2231C9.6155 14.966 8.58232 13.947 7.30781 13.947H3.84627Z"
            stroke="#A9BED0"
            strokeWidth="2.30769"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      title: "My Notes",
      redirectLink: "notes",
      selectedSvgCode: (
        <svg className="w-full" width="16" height="18" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.16667 1.89453V6.39453C9.16667 7.01585 9.689 7.51953 10.3333 7.51953H15M9.36683 1.89453H3.33333C2.04467 1.89453 1 2.90189 1 4.14453V17.6445C1 18.8872 2.04467 19.8945 3.33333 19.8945H12.6667C13.9553 19.8945 15 18.8872 15 17.6445V7.32651C15 6.72977 14.7542 6.15748 14.3166 5.73552L11.0168 2.55354C10.5792 2.13158 9.98567 1.89453 9.36683 1.89453Z"
            stroke="#4E46DC"
            strokeWidth="2"
          />
        </svg>
      ),
      unSelectedSvgCode: (
        <svg className="w-full" width="16" height="18" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.16667 1.89453V6.39453C9.16667 7.01585 9.689 7.51953 10.3333 7.51953H15M9.36683 1.89453H3.33333C2.04467 1.89453 1 2.90189 1 4.14453V17.6445C1 18.8872 2.04467 19.8945 3.33333 19.8945H12.6667C13.9553 19.8945 15 18.8872 15 17.6445V7.32651C15 6.72977 14.7542 6.15748 14.3166 5.73552L11.0168 2.55354C10.5792 2.13158 9.98567 1.89453 9.36683 1.89453Z"
            stroke="#A9BED0"
            strokeWidth="2"
          />
        </svg>
      ),
    },
    {
      title: "My Profile",
      redirectLink: "profile",
      selectedSvgCode: (
        <svg className="w-full" width="16" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.8182 4.90419C11.8182 6.41982 10.5293 7.76944 9 7.76944C7.47067 7.76944 6.18182 6.41982 6.18182 4.90419C6.18182 3.34072 7.51601 1.89453 9 1.89453C10.484 1.89453 11.8182 3.34072 11.8182 4.90419ZM17 14.6724C17 15.3688 16.6212 16.1296 15.3901 16.7712C14.1259 17.4302 12.0587 17.8947 9 17.8947C5.94127 17.8947 3.87409 17.4302 2.60994 16.7712C1.37885 16.1296 1 15.3688 1 14.6724C1 14.123 1.51302 13.3182 3.06076 12.5921C4.52907 11.9033 6.62782 11.4501 9 11.4501C11.3722 11.4501 13.4709 11.9033 14.9392 12.5921C16.487 13.3182 17 14.123 17 14.6724Z"
            stroke="#4E46DC"
            strokeWidth="2"
          />
        </svg>
      ),
      unSelectedSvgCode: (
        <svg className="w-full" width="16" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.8182 4.90419C11.8182 6.41982 10.5293 7.76944 9 7.76944C7.47067 7.76944 6.18182 6.41982 6.18182 4.90419C6.18182 3.34072 7.51601 1.89453 9 1.89453C10.484 1.89453 11.8182 3.34072 11.8182 4.90419ZM17 14.6724C17 15.3688 16.6212 16.1296 15.3901 16.7712C14.1259 17.4302 12.0587 17.8947 9 17.8947C5.94127 17.8947 3.87409 17.4302 2.60994 16.7712C1.37885 16.1296 1 15.3688 1 14.6724C1 14.123 1.51302 13.3182 3.06076 12.5921C4.52907 11.9033 6.62782 11.4501 9 11.4501C11.3722 11.4501 13.4709 11.9033 14.9392 12.5921C16.487 13.3182 17 14.123 17 14.6724Z"
            stroke="#A9BED0"
            strokeWidth="2"
          />
        </svg>
      ),
    },
  ];

  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
    setUser({
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
    });
  }, []);

  return (
    <div id="sidebar" className={`w-full z-10 fixed bottom-0 bg-secondary ease-in-out duration-300 block md:hidden`}>
      <ul className=" flex justify-between items-center gap-2 px-9 ">
        {UpperData.map((item, index) => (
          <li
            key={index}
            className={`py-3 flex flex-col items-center rounded-lg cursor-pointer transition-all duration-200 ease-in ${sidebarClosingStatus ? `px-5 ` : `px-1`} ${
              selectedLink === item.redirectLink ? ` ` : `text-[#344054]`
            }`}
            onClick={() => {
              navigate(`/${item.redirectLink}`);
            }}>
            {selectedLink === item.redirectLink ? item.selectedSvgCode : item.unSelectedSvgCode}
            <p className={`text-[11px]  font-[500] ease-in duration-300 rounded-lg ${selectedLink === item.redirectLink ? `text-primary ` : `text-[#A9BDD0]`} `}>{item.title}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
