import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";


const CheckOut = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col  items-center justify-center mt-40 ">
      <img
        className="w-60 h-60"
        src="https://img.freepik.com/free-vector/online-groceries-concept-illustration_114360-1834.jpg?w=740&t=st=1700476638~exp=1700477238~hmac=040b8fa92feee3981a2b75a738a5857f57a2df8bdbb5b5549173e4445ff874d8"
        alt="success"
      />
      <h1 className="font-bold text-4xl text-emerald-500 font-sans ">
        Paid Successfully !!!
      </h1>

     
      <button className="btn-primary  mt-2 flex flex-row items-center justify-center gap-3" onClick={() => navigate("/")}>
      <FaArrowLeft className="text-[18px]" />
        Get back to home
      </button>
    </div>
  );
};

export default CheckOut;
